import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ThemePalette } from '@angular/material/core';
import { NewgameService } from '../newgame.service';
import { WordListsService } from '../word-lists.service';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
interface User {
  Name: string,
  Role: string
  isAdmin: boolean
}
interface UserId extends User {
  id: any
}
interface Board {
  boardId: string;
  words: string[];
  values: string[];
  status: boolean[];
  turn: string;
  currClue: string;
  redScore: number;
  blueScore: number;
  assassinHit: boolean;
  history: any[];
  isArchive: boolean;
  oldRedScores: number[];
  oldBlueScores: number[];
  restrictMembers: boolean;
  isFamilyFriendly: boolean;
  trackIPAddress: boolean;

  redName: string;
  blueName: string;
  includeAdmin: boolean;
  accessCode: string;
  team1Color: string;
  team2Color: string;
}
interface Timer {
  boardId: string;
  lastUpdated: number;
  initialTime: number;
  spyTime: number;
  playerTime: number;
  active: boolean;
  turn: string;
  paused: boolean;
  pausedTimeRemaining: number;
}
@Component({
  selector: 'app-maingame',
  templateUrl: './maingame.component.html',
  styleUrls: ['./maingame.component.css']
})
export class MaingameComponent {

  title = 'Codenames';
  name: string;
  role: string;
  loggedIn: boolean;
  validBoard: boolean;
  spectateView: string;
  creatingNewGame: boolean;
  wordChoice: string;
  customList: string;
  firstMove: string;
  switchRoles: string;
  //references specific to roles
  RefSpectators: AngularFirestoreCollection<User>;
  spectators: Observable<User[]>;
  refRedPlayer: AngularFirestoreCollection<User>
  redPlayer: Observable<User[]>;;
  refBluePlayer: AngularFirestoreCollection<User>
  bluePlayer: Observable<User[]>;;
  refRedSpymaster: AngularFirestoreCollection<User>
  redSpymaster: Observable<User[]>;
  refBlueSpymaster: AngularFirestoreCollection<User>
  blueSpymaster: Observable<User[]>;

  useTimer: boolean;
  previousTimer: boolean;
  initialMinutes: any;
  initialSeconds: any;
  spyMinutes: any;
  spySeconds: any;
  playerMinutes: any;
  playerSeconds: any;
  timerChoice: string;

  isAdmin: boolean;
  redName: string;
  blueName: string;

  submitCount: number;
  blockedCount: number;

  RefBoard: AngularFirestoreCollection<Board>;
  boards: Observable<Board[]>;

  testDB: AngularFireList<any[]>;

  background: ThemePalette = "accent"

  TimerRef: AngularFirestoreCollection<Timer>;
  timer: Observable<Timer[]>;

  accessCode: string;
  accessGranted: boolean;

  team1Color: string;
  team2Color: string;

  openingSquares: number;
  secondarySquares: number;
  neutralSquares: number;
  assassinSquares: number;

  trackIP: boolean;
  ipAddress: string;
  constructor(private afs: AngularFirestore, private db: AngularFireDatabase,
    private lists: WordListsService, private transfer: NewgameService, private http: HttpClient) { }


  //initializes all listeners to the status of users
  ngOnInit(): void {
    this.useTimer = false;
    this.initialMinutes = 2;
    this.initialSeconds = 0;
    this.spyMinutes = 2;
    this.spySeconds = 0;
    this.playerMinutes = 2;
    this.playerSeconds = 0;
    this.submitCount = 0;
    this.blockedCount = 0;

    this.isAdmin = false;
    this.previousTimer = false;
    this.validBoard = true;

    this.openingSquares = 9;
    this.secondarySquares = 8;
    this.neutralSquares = 7;
    this.assassinSquares = 1;

    this.getIPAddress();


    //make sure there is a board for the URL gone to
    this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(data => {
      if (data[0] == undefined) {
        this.validBoard = false;
      }
      return;
    })


    this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(data => {
      if (!(data == undefined || data.length == 0)) {
        this.timerWasUsed();
      }
      return;
    })

    this.RefSpectators = this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', 'spectator'));
    this.spectators = this.RefSpectators.valueChanges();

    this.refRedSpymaster = this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', 'redSpymaster'));
    this.redSpymaster = this.refRedSpymaster.valueChanges();

    this.refRedPlayer = this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', 'redPlayer'));
    this.redPlayer = this.refRedPlayer.valueChanges();


    this.refBluePlayer = this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', 'bluePlayer'));
    this.bluePlayer = this.refBluePlayer.valueChanges();

    this.refBlueSpymaster = this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', 'blueSpymaster'));
    this.blueSpymaster = this.refBlueSpymaster.valueChanges();

    this.RefBoard = this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId));
    this.boards = this.RefBoard.valueChanges();

    this.TimerRef = this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId));
    this.timer = this.TimerRef.valueChanges();

    this.spectateView = "player";
    this.loggedIn = false;
    this.accessGranted = false;
    this.testDB = this.db.list('/users');
    this.creatingNewGame = false;

    this.boards.subscribe(data => {

      this.redName = data[0].redName;
      this.blueName = data[0].blueName;

      this.team1Color = data[0].team1Color
      //sets the color property of all elements that should be colored
      this.team2Color = data[0].team2Color;

      this.trackIP = data[0].trackIPAddress;
    })
  }
  Login() {

    if (this.name == undefined || this.name == '') {
      alert("You must enter a name");
      return;
    }
    if (this.role == undefined) {
      alert("You must give a role");
      return;
    }
    let today = new Date();
    let dateString = today.getUTCFullYear() + '-' + (String(today.getUTCMonth() + 1)).padStart(2, '0') + '-' + String(today.getUTCDate()).padStart(2, '0') + '  ' +   (String(today.getUTCHours())).padStart(2, '0') + ':' + (String(today.getUTCMinutes())).padStart(2, '0') + ':' + (String(today.getUTCSeconds())).padStart(2, '0')
    if (this.ipAddress != null && this.ipAddress != undefined && this.trackIP) {      
      this.afs.collection('IPHistory').add({ 'Name': this.name, 'BoardID': this.boardId, 'Date': dateString, 'IPAddress': this.ipAddress, 'CreatedDate': today.getTime() })
    }
    //If they have IP blocker on we will not let them login, assuming ip tracking is turned on for the board
    else if(this.trackIP){
      this.afs.collection('IPHistory').add({ 'Name': this.name, 'BoardID': this.boardId, 'Date': dateString, 'IPAddress': 'Login Rejected - IP not available', 'CreatedDate': today.getTime()  })
     
      //If they've ignored warnings to turn of ip blocker we'll redirect them elsewhere
      if(this.blockedCount >= 2){
        window.location.href = "https://www.youtube.com/watch?v=0noiZWAZtyA"
      }
      else{
        alert("To help protect the community and ensure respectful behavior, we do not allow IP blocker to be used while in the game.  Please turn it off.")
        this.blockedCount++; 
      }
      return;
    }
    let check = false;  //this means we have entered as a non-spectator validly
    let nameCheck = false;
    if (this.role != 'spectator') {

      let adminCheck = false;

      this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('isAdmin', '==', true)).valueChanges().
        subscribe(adminData => {
          if (adminCheck) {

            return
          }
          adminCheck = true
          //if trying to be an admin, make sure to not
          if (this.isAdmin && adminData[0] != undefined) {

            alert("Somebody else has already claimed the admin privilidges for this match!")
            return;
          }
          this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Role', '==', this.role)).valueChanges().
            subscribe(data => {

              //only want to proceed if this position is not already taken
              if (data[0] == undefined) {
                if (check) {  //if we've already entered but something changed and we are not registered
                  this.loggedIn = false;
                  this.submitCount = 0;
                  return;
                }
                check = true;

                let boardCheck = false;
                this.boards.subscribe(boardData => {

                  if (boardCheck) {
                    return;
                  }
                  boardCheck = true;

                  if (boardData[0].restrictMembers && !this.lists.userList.includes(this.name.toLowerCase())) {
                    alert("Only approved names (primarily mH usernames), are allowed for this match");
                    return;
                  }

                  if (boardData[0].isFamilyFriendly && this.lists.isWordNaughty(this.name)) {
                    alert("Your entered name includes a word/phrase that is banned. We're trying to keep the site family-friendly");
                    return;
                  }
                  this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Name', '==', this.name)).valueChanges().subscribe
                    (data => {
                      if (nameCheck) {
                        return;
                      }
                      nameCheck = true;

                      if (data[0] == undefined && this.submitCount == 0) {
                        this.submitCount++
                        this.afs.collection('User').add({ 'Name': this.name, 'Role': this.role, 'BoardId': this.boardId, 'isAdmin': this.isAdmin })
                          .then(docRef => {

                            //this lets us listen in on user and triggers stuff when they disconnect, keeping us informed
                            this.testDB.push([{ UserId: docRef.id }]).onDisconnect().remove();
                            this.loggedIn = true;
                          });
                      }
                      else {
                        alert("Somebody in this match already is called this")
                      }
                    });
                })
                //here we ensure nobody in board has currently desired name

              }
              else if (data[0] != undefined && !check) {
                alert("The position of " + this.role + " has already been taken");
                return;
              }
            });
        });

    }
    else {

      let adminCheck = false;

      this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('isAdmin', '==', true)).valueChanges().
        subscribe(adminData => {
          if (adminCheck) {

            return
          }
          adminCheck = true
          //if trying to be an admin, make sure to not
          if (this.isAdmin && adminData[0] != undefined) {

            alert("Somebody else has already claimed the admin privilidges for this match!")
            return;
          }

          let boardCheck = false;
          this.boards.subscribe(boardData => {
            if (boardCheck) {
              return;
            }
            boardCheck = true;
            if (boardData[0].restrictMembers && !this.lists.userList.includes(this.name.toLowerCase())) {
              alert("Only approved names (primarily mH usernames), are allowed for this match");
              return;
            }

            if (boardData[0].isFamilyFriendly && this.lists.isWordNaughty(this.name)) {
              alert("Your entered name includes a word/phrase that is banned. We're trying to keep the site family-friendly");
              return;
            }
            var test = this.lists.naughtyWordList.toString();
            this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Name', '==', this.name)).valueChanges().subscribe
              (data => {
                if (nameCheck && data[0] != undefined) {  //we already added ourselves, don't need to run again
                  return;
                }
                else if (nameCheck && data[0] == undefined) {  //we had added ourselves, but got disconnected so need to relog in
                  this.loggedIn = false;
                  return;
                }
                nameCheck = true;

                if (data[0] == undefined) {

                  this.afs.collection('User').add({ 'Name': this.name, 'Role': this.role, 'BoardId': this.boardId, 'isAdmin': this.isAdmin })
                    .then(docRef => {

                      //this lets us listen in on user and triggers stuff when they disconnect, keeping us informed
                      this.testDB.push([{ UserId: docRef.id }]).onDisconnect().remove();
                      this.loggedIn = true;
                    });
                }
                else {
                  alert("Somebody in this match already is called this")
                }
              });
          })
        })
    }
  }
  startMatch() {
    this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
      startMatch: true
    });
    let check = false;
    this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(data => {
      if (check) {
        return;
      }
      check = true;

      if (data != undefined && data.length != 0) {
        this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
          active: true,
          lastUpdated: new Date().getTime()
        })
      }
    })
  }
  newGame() {
    this.creatingNewGame = true;
  }
  cancelNewGame() {
    this.creatingNewGame = false;
  }
  submitNewGame() {
    if (this.wordChoice == undefined) {
      alert("You must choose an option for type of words to use");
      return;
    }

    if (this.wordChoice == "custom" && (this.customList == undefined || this.customList == '')) {
      alert("Must provide a list of words if you choose custom list");
      return;
    }
    if (this.firstMove == undefined) {
      alert("Please choose an option for who moves first");
      return;
    }
    if (this.switchRoles == undefined) {
      alert("Choose if you want the roles to be reoponed for new game");
      return;
    }

    if (isNaN(this.openingSquares) || isNaN(this.secondarySquares) ||
      isNaN(this.assassinSquares) || isNaN(this.neutralSquares) || this.openingSquares <= 0 || this.secondarySquares <= 0 ||
      this.neutralSquares < 0 || this.assassinSquares < 0
    ) {
      alert("All selections for number of squares for each category must be positive numbers (assasin/neutral can be 0)");
      return;
    }
    if (25 - this.openingSquares - this.secondarySquares - this.assassinSquares - this.neutralSquares != 0) {
      alert("Selection for number of squares for each category must add to 25");
      return;
    }

    let initialTotal = 0;
    let spyTotal = 0;
    let playerTotal = 0;
    let timerUse = "";

    if (this.useTimer && this.previousTimer && this.timerChoice == undefined) {
      alert("Please select a timer option (previous or new)")
      return;
    }
    //If using new custom time, this checks all inputs were valid
    if (this.useTimer && (!this.previousTimer || this.timerChoice == "new")) {

      if (isNaN(this.initialMinutes) || isNaN(this.initialSeconds) ||
        isNaN(this.spyMinutes) || isNaN(this.spySeconds) ||
        isNaN(this.playerMinutes) || isNaN(this.playerSeconds)
      ) {
        alert("invalid time input.  They must all be numbers");
        return;
      }
      else if (!(this.initialMinutes <= 9 && this.initialMinutes >= 0) ||
        !(this.spyMinutes <= 9 && this.spyMinutes >= 0) ||
        !(this.playerMinutes <= 9 && this.playerMinutes >= 0) ||
        this.initialMinutes % 1 != 0 || this.spyMinutes % 1 != 0 || this.playerMinutes % 1 != 0) {
        alert("One of timer minutes input is invalid.  Must be between 0 and 9 and be whole number");
        return;
      }
      else if (!(this.initialSeconds <= 59 && this.initialSeconds >= 0) ||
        !(this.spySeconds <= 59 && this.spySeconds >= 0) ||
        !(this.playerSeconds <= 59 && this.playerSeconds >= 0) ||
        this.initialSeconds % 1 != 0 || this.spySeconds % 1 != 0 || this.playerSeconds % 1 != 0) {
        alert("One of timer seconds input is invalid.  Must be between 0 and 59 and be whole number");
        return;
      }
      initialTotal = (Number(this.initialMinutes * 60) + Number(this.initialSeconds));
      spyTotal = (Number(this.spyMinutes * 60) + Number(this.spySeconds));
      playerTotal = (Number(this.playerMinutes * 60) + Number(this.playerSeconds));

      if (!this.previousTimer) {
        timerUse = "new";
      }
      else if (this.timerChoice == "new") {
        timerUse = "update"
      }
    }
    else if (this.useTimer && this.timerChoice == "previous") {
      timerUse = "previous"
    }
    else if (!this.useTimer) {
      if (this.previousTimer) {
        timerUse = "delete";
      }
      else {
        timerUse = "none"
      }
    }
    let check = false;
    this.RefBoard.valueChanges().subscribe(data => {
      let winner = "";
      if (check) {
        return;
      }
      check = true;

      if (data[0].redScore == 0 || (data[0].assassinHit && data[0].turn == "bluePlayer")) {
        winner = "red"
      }
      else {
        winner = "blue"
      }
      if (this.switchRoles == "true") {
        this.transfer.createNewGame(this.openingSquares, this.secondarySquares, this.neutralSquares, this.assassinSquares,
          this.team1Color, this.team2Color, this.boardId, this.wordChoice, this.customList, this.firstMove, true, winner,
          timerUse, initialTotal, spyTotal, playerTotal);
      }
      else {
        this.transfer.createNewGame(this.openingSquares, this.secondarySquares, this.neutralSquares, this.assassinSquares,
          this.team1Color, this.team2Color, this.boardId, this.wordChoice, this.customList, this.firstMove, false, winner,
          timerUse, initialTotal, spyTotal, playerTotal);
      }
      this.creatingNewGame = false;
    })
  }
  get boardId(): string {
    let param = 'id'
    var match = RegExp('[?&]' + param + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  get isPlayer(): boolean {
    return (this.role == "redPlayer" || this.role == "bluePlayer");
  }
  get isSpymaster(): boolean {
    return (this.role == "redSpymaster" || this.role == "blueSpymaster");
  }
  get isSpectator(): boolean {
    return (this.role == "spectator");
  }
  viewSpymaster() {
    this.spectateView = "spymaster"
  }
  viewPlayer() {
    this.spectateView = "player"
  }
  timerWasUsed() {
    this.useTimer = true;
    this.previousTimer = true;
  }

  LockGame() {
    this.accessCode = this.getUniqueId()
    this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
      locked: true,
      accessCode: this.accessCode
    });

  }
  UnlockGame() {

    this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
      locked: false,
      accessCode: ''
    });

  }

  requestAccess() {
    let check = false;
    this.RefBoard.valueChanges().subscribe(data => {
      if (check) {
        return;
      }
      check = true;
      if (this.accessCode == data[0].accessCode) {
        this.accessGranted = true;
      }
      else {
        alert("This is not the access code given");
      }
    })
  }

  getUniqueId() {
    return Math.random().toString(36).substr(2, 8);
  }

  removeUser(name) {
    let userCheck = false;

    this.afs.collection('User', ref => ref.where('BoardId', '==', this.boardId).where('Name', '==', name)).snapshotChanges().pipe(map(userData => {
      return userData.map(a => {
        return { id: a.payload.doc.id };
      })

    })).subscribe(found => {
      if (userCheck) {
        return;
      }
      userCheck = true;

      this.afs.collection('User').doc(found[0].id).delete();
    })
  }

  pauseTimer() {
    let intervalTime = 0;
    let check = false
    this.TimerRef.valueChanges().subscribe(data => {
      if (check) {
        return
      }
      check = true;

      if (data[0].turn == "boardPreview") {
        intervalTime = data[0].initialTime
      }
      else if (data[0].turn == "redSpymaster" || data[0].turn == "blueSpymaster") {
        intervalTime = data[0].spyTime
      }
      else if (data[0].turn == "redPlayer" || data[0].turn == "bluePlayer") {
        intervalTime = data[0].playerTime
      }
      let secondsLeft = intervalTime - (new Date().getTime() - data[0].lastUpdated) / 1000;

      this.afs.collection('Timer').doc(this.boardId.valueOf() + "_timer").update({
        'paused': true,
        'pausedTimeRemaining': secondsLeft
      });
    });
  }
  restartTimer() {
    let check = false
    this.TimerRef.valueChanges().subscribe(data => {
      if (check) {
        return
      }
      check = true;
      let intervalTime = 0;

      if (data[0].turn == "boardPreview") {
        intervalTime = data[0].initialTime
      }
      else if (data[0].turn == "redSpymaster" || data[0].turn == "blueSpymaster") {
        intervalTime = data[0].spyTime
      }
      else if (data[0].turn == "redPlayer" || data[0].turn == "bluePlayer") {
        intervalTime = data[0].playerTime
      }

      this.afs.collection('Timer').doc(this.boardId.valueOf() + "_timer").update({
        'paused': false,
        lastUpdated: new Date().getTime() - ((intervalTime - data[0].pausedTimeRemaining) * 1000)
      })
    })
  }
  removeTimer() {
    if (confirm("Are you sure to permanently remove the timer for the current game?")) {
      this.afs.collection('Timer').doc(this.boardId.valueOf() + "_timer").update({
        active: false
      })
    }
  }

  getIPAddress() {

    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;

    });

  }

}

