import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { WordListsService } from './word-lists.service';
import { map } from 'rxjs/operators';

interface User {
  Name: string,
  Role: string
}

interface Board {
  boardId: string;
  words: string[];
  values: string[];
  status: boolean[];
  turn: string;
  currClue: string;
  redScore: number;
  blueScore: number;
  assassinHit: boolean;
  history: any[];
  blueGamesWon: number;
  redGamesWon: number;
  firstMatch: boolean;
  prevGames: string[];
  prevWinners: string[];
  oldBlueScores: number[];
  oldRedScores: number[];
  restrictMembers: boolean;
  redName : string;
  blueName: string;

  isFamilyFriendly: boolean;
  trackIPAddress: boolean;

}


@Injectable({
  providedIn: 'root'
})
export class NewgameService {

  RefBoard: AngularFirestoreCollection<Board>;
  redWins: number;
  blueWins: number;
  winners: string[];
  games: string[];
  blueScore: number;
  redScore: number;
  oldBlueScores: number[];
  oldRedScores: number[];
  constructor(private afs: AngularFirestore, private wordArchive: WordListsService) { }

  ngOnInit(): void {

  }

  //this is when current game finished and user decides to quickly start new game with same URL
  public createNewGame(openingSquares: number, secondarySquares:number, neutralSquares:number, assassinSquares:number, team1Color: string, team2Color:string, boardId: string, wordChoice: string, wordList: string, firstMove: string, changeRoles: boolean, newWinner: string,
    timerMode: string, initialSeconds: number, spySeconds: number, playerSeconds: number) {
    let check = false;
    this.RefBoard = this.afs.collection('Board', ref => ref.where('boardId', '==', boardId));

 
    if(timerMode == "new"){
      let timerId = boardId + "_timer";
      let startingTeam = firstMove + 'Spymaster'

      this.afs.collection('Timer').doc(timerId).set({
        'lastUpdated': 0, 'initialTime': initialSeconds,
        'spyTime': spySeconds, 'playerTime': playerSeconds,
        'active': false, 'boardId': boardId, 'paused': false, 'turn': 'boardPreview', 'firstMove': startingTeam,
        'pausedTimeRemaining': initialSeconds
      });
    }
    else if(timerMode == "update"){
      let startingTeam = firstMove + 'Spymaster'
      this.afs.collection('Timer', ref => ref.where('boardId', '==', boardId)).doc(boardId.valueOf() + "_timer").update({
        
        'initialTime': initialSeconds,
        'spyTime': spySeconds,
        'playerTime': playerSeconds,
        'pausedTimeRemaining': initialSeconds,
        'paused': false,
        'turn': 'boardPreview',
        'firstMove': startingTeam
      })
    }
    else if(timerMode == "delete"){
      this.afs.collection('Timer', ref => ref.where('boardId', '==', boardId)).doc(boardId.valueOf() + "_timer").delete();
    }
    
    this.RefBoard.valueChanges().subscribe(data => {
      if (check) {
        return;
      }
      check = true;
      this.redWins = data[0].redGamesWon;
      this.blueWins = data[0].blueGamesWon;
      this.winners = data[0].prevWinners;
      this.games = data[0].prevGames.slice();
      this.redScore = data[0].redScore;
      this.blueScore = data[0].blueScore;
      this.oldRedScores = data[0].oldRedScores.slice();
      this.oldBlueScores = data[0].oldBlueScores.slice();


      let newId: string = data[0].boardId + '_' + (data[0].prevGames.length + 1);

      //below makes sure this hardcoded code doesn't already exist
      let checkBoard: AngularFirestoreCollection<Board> = this.afs.collection('Board', ref => ref.where('boardId', '==', newId));
      let checkTwo = false;
      checkBoard.valueChanges().subscribe(checkData => {
        if (checkTwo) {
          return;
        }
        checkTwo = true;
        //if it already exists, we append a random id to it
        if (checkData.length != 0) {
          newId = data[0].boardId + '_' + this.getUniqueId();
        }
        this.games.push("https://mycodenames.com/?id=" + newId);

        //just copy everything to new board, except the id fields, which we just generated
        this.afs.collection('Board').doc(newId).set({
          'boardId': newId, 'words': data[0].words,
          'values': data[0].values, 'status': data[0].status, 'turn': data[0].turn, 'currClue': '',
          'redScore': data[0].redScore, 'blueScore': data[0].blueScore, 'assassinHit': data[0].assassinHit, 'startMatch': true,
          'history': data[0].history, 'currGuesses': [], 'createDate': new Date().getTime(),
          'blueGamesWon': data[0].blueGamesWon, 'redGamesWon': data[0].redGamesWon, 'firstMatch': data[0].firstMatch, 'prevGames': data[0].prevGames, 'prevWinners': data[0].prevWinners,
          'isArchive': true, 'oldBlueScores': data[0].oldBlueScores, 'oldRedScores': data[0].oldRedScores, 'restrictMembers': data[0].restrictMembers,
          'redName': data[0].redName, 'blueName': data[0].blueName, 'team1Color': team1Color, 'team2Color': team2Color,
          'trackIPAddress': data[0].trackIPAddress, 'isFamilyFriendly': data[0].isFamilyFriendly
        })
          //once data has been copied to board, we will now clear this one with given params
          .finally(() => {

            let valueOptions: string[];
            let blueScore: number;
            let redScore: number;


            if (firstMove == "blue") {
              firstMove = "blueSpymaster"
              blueScore = openingSquares;
              redScore = secondarySquares;
            }
            else if (firstMove == "red") {
              firstMove = "redSpymaster";
              blueScore = secondarySquares;
              redScore = openingSquares;
            }
            else {    //they chose random first move
              let choice = Math.floor(Math.random() * 2);
              if (choice == 0) {
                firstMove = "blueSpymaster";
                blueScore = openingSquares;
                redScore = secondarySquares;
              }
              else if (choice == 1) {
                firstMove = "redSpymaster";
                blueScore = openingSquares;
                redScore = secondarySquares;
              }
            }
             valueOptions = []
            for (let i = 0; i < assassinSquares; i++) {
              valueOptions.push("assassin");
            }
            for (let i = 0; i < neutralSquares; i++) {
              valueOptions.push("neutral");
            }
            for (let i = 0; i < blueScore; i++) {
              valueOptions.push("blue");
            }
            for (let i = 0; i < redScore; i++) {
              valueOptions.push("red");
            }
            //get the word options
            let wordOptions: string[];
            if (wordChoice == "normal") {
              wordOptions = this.wordArchive.normalWords;
            }
            else if (wordChoice == "potter") {
              wordOptions = this.wordArchive.potterWords;
            }
            else if (wordChoice == "myHogwarts") {
              wordOptions = this.wordArchive.mHWords;
            }
            else if (wordChoice == "custom") {    //we have custom list....yeah?

              wordOptions = wordList.split(",");

              if (wordOptions.length < 25) {
                alert("not enough words were provided");
                return;
              }
            }
            let valueSelections: string[] = [];
            let wordSelections: string[] = [];  //this will be the current selection of words

            //choose the words to be used
            while (wordSelections.length < 25) {
              let word = wordOptions[Math.floor(Math.random() * wordOptions.length)]
              if (!wordSelections.includes(word)) {  //make sure it wasn't already added
                wordSelections.push(word);
              }
            }
            //chooses where the values go
            let positionsRemaining: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
            for (let i = 0; i < 25; i++) {

              let valueIndex = Math.floor(Math.random() * valueOptions.length);
              let positionIndex = Math.floor(Math.random() * positionsRemaining.length);
              valueSelections[positionsRemaining[positionIndex]] = valueOptions[valueIndex];
              valueOptions.splice(valueIndex, 1);  //This just removes the appropriate element of array
              positionsRemaining.splice(positionIndex, 1);
            }
            let startingStatus = [false, false, false, false, false, false, false, false, false, false, false, false, false,
              false, false, false, false, false, false, false, false, false, false, false, false];

              if(newWinner == "blue"){
                this.winners.push("blue");
              }
              else{
                this.winners.push("red");
              }
              this.oldBlueScores.push(this.blueScore);
              this.oldRedScores.push(this.redScore);

              if(timerMode == "new" || timerMode == "update" || timerMode == "previous"){
                firstMove = "boardPreview"
              }
            this.RefBoard.doc(boardId).update({
              'words': wordSelections,
              'values': valueSelections, 'status': startingStatus, 'turn': firstMove, 'currClue': '',
              'redScore': redScore, 'blueScore': blueScore, 'assassinHit': false, 'startMatch': false,
              'history': [], 'currGuesses': [], 'createDate': new Date().getTime(),
              'blueGamesWon':this.blueWins , 'redGamesWon': this.redWins, 'firstMatch': false, 'prevGames': this.games, 'prevWinners': this.winners,
              'oldBlueScores': this.oldBlueScores, 'oldRedScores': this.oldRedScores
            })
              //once the transfer has completed, now clear participants if desired
              .finally(() => {

                if (changeRoles) {
                  let userCheck = false;                                                                                          
                  let RefUser: AngularFirestoreCollection<User> = this.afs.collection('User', ref => ref.where('BoardId', '==', boardId));
   
                 RefUser.snapshotChanges().pipe(map(userData => {
                    return userData.map(a => {
                        return {id: a.payload.doc.id, data: {...a.payload.doc.data()}};
                    })
                    
                  })).subscribe(found =>{
                    if(userCheck){
                      return;
                    }
                    userCheck = true;
                    for(let i = 0; i< found.length; i++){
                    if(found[i].data.Role == "blueSpymaster" || found[i].data.Role == "redSpymaster" ||
                    found[i].data.Role == "bluePlayer" || found[i].data.Role == "redPlayer"){
                      RefUser.doc(found[i].id).delete();
                    }
                  }
                  })
                
                }
              })

          })

      })

    })
  }
  getUniqueId() {
    return Math.random().toString(36).substr(2, 8);
  }
}
