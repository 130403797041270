<h2>Game rules</h2>
<ol>
  <li>There are two teams (by default colored blue and red, which is used for below as example), each with two people - a spymaster and a player.</li>
  <li>There is a 5x5 grid of words/phrases. The spymasters can see the values associated with each word, the players
    cannot (until it has been guessed)</li>
  <li>Each word can have the value of: red, blue, neutral, or assassin</li>
  <li>At the start of a team's turn, the spymaster provides a clue. Their goal is to provide a clue that will lead
    their player to click on words belonging to their team (red or blue)</li>
  <li>The clue consists of a word and a number. The clue word should be something that is associated with the
    words/phrases you want your player to guess, the number is how many words/phrases you want them to guess</li>
  <li>Example: "hot 2" could be a good clue to use if you are a blue spymaster and the words fire and sun are blue
  </li>
  <li>Their clue cannot be a word or significant part of a word of any word/phrase that has not been guessed yet by
    a player</li>
  <li>Once the spymaster has given their clue, the player can guess, one at a time, what words they believe their
    spymaster was going for</li>
  <li>Each team's score is determined by how many words/phrases their player has yet to guess of their color. The
    goal of the game is for your team's player to find all of their word/phrases (making their score 0) </li>
  <li>If the player chooses one of their words/phrases, a point is subtracted from their score and they may continue
    guessing</li>
  <li>If the player guesses a word/phrase of their opponents, the opponent has a point subtracted from their score
    and the turn automatically ends and goes to the opponent</li>
  <li>If the player guesses a word/phrase that is neutral, there is no score change but the turn automatically ends
    and goes to your opponent</li>
  <li>If the player guesses the assassin word/phrase, their team automatically loses and the game is over</li>
  <li>The player must first go after the words that they believe the spymaster was cluing them in on. Afterwards,
    they may choose to go for bonus words that they missed from previous clues (or just a desperate random guess :P
    )</li>
  <li>Common rules state that the player can guess as many words/phrases as they want and can choose to end their
    turn if they aren't confident at any point</li>
  <li>myHogwarts specific: The player must guess at least one word, and they may only guess one bonus per turn (so
    if spymaster clue was "hot 2", the player can guess at most 3 things)</li>
  <li>Once your turn ends (whether by choice or incorrect guess), play will continue with the other team's
    spymaster. This is repeated until there is a winner/loser</li>
  <li>The team who goes first has 9 words/phrases; team that goes second has 8 on the grid to start. There are 7
    neutrals and 1 assassin</li>
  <li>For spymaster, words are distinguished with team colors, black for neutral, and dark background with white
    text for assassin</li>
  <li>Spectators can see both boards but cannot interact with them in any way</li>
</ol><br>

<h2>Site Info</h2>
<ol>
  <li>Random boards randomizes the words and will use normal words, will not use a timer, leaves team names and colors as red and blue</li>
  <li>Currently the site prevents more than one of any position (not including spectator of course) from joining a match as well as each game can only have one person with a given name</li>
  <li>All data related to match will be kept around for a month, at which point it may be deleted at any point (as soon as a new board is generated to be exact)</li>
  <li>The list of people viewing the board is live.  This means if you get disconnected for some reason, you should be 
    able to reconnect without a problem (give it at least 15 seconds though, it takes time to recognize that you disconnected) and reclaim your old position (or take a new one if there was a mistake during match setup)
  </li>
  <li>If you get kicked out of match (meaning your local page asks you to log back in) but see your name still listed, just refresh the page</li>
  <li>Family friendly mode is turned on by default.  It can be turned off if desired for some reason in the custom board setting</li>
  <li>When using timer, it is required to allow someone to have admin entitlement for the match.  Timer will only move the turn upon expiring if admin is connected</li>
  <li>IP address of users is tracked by default, it can be turned off in custom board settings</li>
</ol>