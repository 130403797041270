<div id="board" align="center">
    <ng-container *ngFor="let board of boards |async">
        <ng-container *ngIf="board.redScore > 0 && board.blueScore> 0 && board.assassinHit == false">

            <div *ngIf="board.turn == 'boardPreview' && role != 'spectator'">
                <span>
                    <font color="white" size=10>Now's the time to become one with the board!</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn == 'boardPreview' && role == 'spectator'">
                <span>
                    <font color="white" size=10>Participants are hopefully studying, chaos will ensue momentarily</font>
                </span>
                <br><br><br>
            </div>

            <div *ngIf="board.turn == 'redPlayer'">
                <span>
                    <font [color]="team2Color" size=10>{{board.currClue}}</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'bluePlayer'">
                <span>
                    <font [color]="team1Color" size=10>{{board.currClue}}</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'blueSpymaster' && role == 'bluePlayer'">
                <span>
                    <font [color]="team1Color" size=10>Hopefully your spymaster finds something good...</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'redSpymaster' && role == 'redPlayer'">
                <span>
                    <font [color]="team2Color" size=10>Hopefully your spymaster finds something good...</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'blueSpymaster' && role == 'redPlayer'">
                <span>
                    <font [color]="team1Color" size=10>Their turn! Relax, and maybe talk some smack?</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'redSpymaster' && role == 'bluePlayer'">
                <span>
                    <font [color]="team2Color" size=10>Their turn! Relax, and maybe talk some smack?</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'blueSpymaster' &&  role == 'spectator'">
                <span>
                    <font [color]="team1Color" size=6>Spymaster thinking in place. A special moment may be just a click away...</font>
                </span>
                <br><br><br>
            </div>
            <div *ngIf="board.turn  == 'redSpymaster' &&  role == 'spectator'">
                <span>
                    <font [color]="team2Color" size=6>Spymaster thinking in place. A special moment may be just a click away...</font>
                </span>
                <br><br><br>
            </div>
            <ng-container *ngIf="role == board.turn">
                <button (click)=endTurn()>End Turn</button><br><br>
                <ng-container *ngFor="let word of board.words; let i = index">

                    <div (click)="tileClicked([board.status, i])" class="square" [ngClass]="{
                        'unclicked':  word.length < 20,
                        'unclickedLarge': word.length >= 20}" 
            [ngStyle]= "{'background-color': getBackgroundColor(board.status[i], board.values[i])}">
            <span [ngStyle] = "{'color': getTextColor(board.status[i], board.values[i])}">{{word}}</span></div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="board.redScore == 0 || (board.assassinHit == true && board.turn == 'bluePlayer')">
            <font [color]="team2Color" size=12>{{redName}} Wins!</font><br>
            
        </ng-container>

        <ng-container *ngIf="board.blueScore == 0 || (board.assassinHit == true && board.turn == 'redPlayer')">
            <font [color]="team1Color" size=12>{{blueName}} Wins!</font><br>
        </ng-container>

        <ng-container *ngIf="role != board.turn || board.blueScore == 0 || board.redScore == 0 || board.assassinHit == true">
            <ng-container *ngFor="let word of board.words; let i = index">
                <div class="square" [ngClass]="{
                    'unclicked':  word.length < 20,
                    'unclickedLarge': word.length >= 20}" 
        [ngStyle]= "{'background-color': getBackgroundColor(board.status[i], board.values[i])}">
            <span [ngStyle] = "{'color': getTextColor(board.status[i], board.values[i])}">{{word}}</span></div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>