import { Component, OnInit } from '@angular/core';
import { logging } from 'protractor';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs';

interface Lists {
  HPWordList: string[];
  NormalList: string[];
  mHUserList: string[];
  mHWordList: string[];
  NaughtyWordList: string[];
  Admin_Login: { Username: string, Password: string }
}
interface ipHistory {
  Name: string;
  BoardID: string;
  Date: Date;
  IPAddress: string;
}
interface BannedIP {
  IPAddress: string;
  LinkedName: string;
  BannedDate: Date;
}
@Component({
  selector: 'app-maint',
  templateUrl: './maint.component.html',
  styleUrls: ['./maint.component.css']
})
export class MaintComponent implements OnInit {

  RefLists: AngularFirestoreCollection<Lists>;
  IPRef: AngularFirestoreCollection<ipHistory>;
  bannedIPRef: AngularFirestoreCollection<BannedIP>

  constructor(private afs: AngularFirestore) { }

  username: string;
  password: string;
  loggedin: boolean
  mHNameList: string
  normalList: string
  HPList: string
  mHWordList: string
  naughtyWordList: string;
  background: ThemePalette = "accent"

  ipHistoryData: ipHistory[];
  ipBannedData: BannedIP[];

  ngOnInit(): void {
    this.RefLists = this.afs.collection('Lists')
    this.IPRef = this.afs.collection('IPHistory')
    this.bannedIPRef = this.afs.collection('BannedIPs')

    this.loggedin = false
    let check = false

    this.RefLists.valueChanges().subscribe(data => {
      if (check) {
        return
      }
      check = true

      this.mHNameList = data[0].mHUserList.toString()
      this.normalList = data[0].NormalList.toString()
      this.HPList = data[0].HPWordList.toString()
      this.mHWordList = data[0].mHWordList.toString()
      this.naughtyWordList = data[0].NaughtyWordList.toString();
    })

    this.IPRef.valueChanges().subscribe(ipData => {
      this.ipHistoryData = ipData.sort((a, b) => a.Date > b.Date ? -1 : 1);
    });

    this.bannedIPRef.valueChanges().subscribe(ipBanData => {
      this.ipBannedData = ipBanData.sort((a, b) => a.BannedDate > b.BannedDate ? -1 : 1);
    });
  }

  login() {

    let check = false

    this.RefLists.valueChanges().subscribe(data => {
      if (check) {
        return
      }
      check = true

      if (data[0].Admin_Login.Username === this.username && data[0].Admin_Login.Password === this.password) {
        this.loggedin = true
      }
      else {
        alert("Incorrect Login")
        return
      }

    })
  }
  confirmusers() {
    let confirmation = confirm("Are you sure you want to submit the below mH User list")
    if (confirmation) {
      this.RefLists.doc("Lists".valueOf()).update({
        mHUserList: this.mHNameList.split(',')
      }).finally(() => {
        alert("mH User List updated")
      });
    }
  }
  confirmNormal() {
    let confirmation = confirm("Are you sure you want to submit the below normal word list")
    if (confirmation) {
      this.RefLists.doc("Lists".valueOf()).update({
        NormalList: this.normalList.split(',')
      }).finally(() => {
        alert("Normal word list updated")
      });
    }
  }
  confirmPotter() {
    let confirmation = confirm("Are you sure you want to submit the below Harry Potter word?")
    if (confirmation) {
      this.RefLists.doc("Lists".valueOf()).update({
        HPWordList: this.HPList.split(',')
      }).finally(() => {
        alert("Harry Potter word list updated")
      });
    }
  }
  confirmmH() {
    let confirmation = confirm("Are you sure you want to submit the below mH word list?")
    if (confirmation) {
      this.RefLists.doc("Lists".valueOf()).update({
        mHWordList: this.mHWordList.split(',')
      }).finally(() => {
        alert("mH word list updated")
      });
    }
  }
  naughtyConfirmation() {
    let confirmation = confirm("Are you sure you want to submit the below naughty word list?")
    if (confirmation) {
      this.RefLists.doc("Lists".valueOf()).update({
        NaughtyWordList: this.naughtyWordList.split(',')
      }).finally(() => {
        alert("naughty word list updated")
      });
    }
  }
  banIP(ipEntry: ipHistory) {
    let confirmation = confirm("Are you sure you want to ban the IP address - " + ipEntry.IPAddress + " for their activity under then name of " + ipEntry.Name + "?  They will no longer be able to access the site.  This can be undone below anytime")
    if (confirmation) {
      this.afs.collection('BannedIPs').doc(ipEntry.IPAddress).set({ 'IPAddress': ipEntry.IPAddress, 'LinkedName': ipEntry.Name, 'BannedDate': new Date().toUTCString() })
        .finally(() => {
          alert("This IP has been banned")
        });
    }
  }

  unBanIP(IPAddress: string, LinkedName: string) {
    let confirmation = confirm("Are you sure you want to unban the IP address - " + IPAddress + " which had associated name " + LinkedName + "?  They will regain access to the site immediately.")
    if (confirmation) {
      this.afs.collection('BannedIPs').doc(IPAddress).delete()
        .finally(() => {
          alert("This IP has been unbanned")
        });
    }
  }
  isIPBanned(IPAddress: string): boolean {
    let found = false
    this.ipBannedData.forEach(ipEntry => {
      if (ipEntry.IPAddress == IPAddress)
        found = true;
    })
    return found;
  }
}
