import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

interface Board {
  boardId: string;
  words: string[];
  values: string[];
  status: boolean[];
  turn: string;
  currClue: string;
  redScore: number;
  blueScore: number;
  assassinHit: boolean;
  startMatch: boolean;
  history: any[];
  currGuesses: any[];
  blueGamesWon: number;
  redGamesWon: number;
  firstMatch: boolean;
  redName: string;
  blueName: string;

  team1Color: string;
  team2Color: string;
}
interface Timer {
  boardId: string;
  lastUpdated: number;
  initialTime: number;
  spyTime: number;
  playerTime: number;
  active: boolean;
  turn: string;
  paused: boolean;
  pausedTimeRemaining: number;
}
@Component({
  selector: 'app-player-board',
  templateUrl: './player-board.component.html',
  styleUrls: ['./player-board.component.css']
})
export class PlayerBoardComponent {

  @Input() boardId: String;
  @Input() isParticipant: boolean;
  @Input() role: string;

  RefBoard: AngularFirestoreCollection<Board>;
  boards: Observable<Board[]>;
  constructor(private afs: AngularFirestore) { }
  words: string[];

  redName: string;
  blueName: string;

  team1Color: string;
  team2Color: string;

  ngOnInit(): void {
    this.RefBoard = this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId));
    this.boards = this.RefBoard.valueChanges();


    this.boards.subscribe(data => {
      this.redName = data[0].redName;
      this.blueName = data[0].blueName;

      this.team1Color = data[0].team1Color;
      this.team2Color = data[0].team2Color;
    })
  }

  getBackgroundColor(chosen: boolean, value: string) {

    if (!chosen) {
      return 'grey'
    }
    else if (chosen) {
      if (value == "blue") {
        return this.team1Color
      }
      else if (value == "red") {
        return this.team2Color
      }
      else if (value == "neutral") {
        return "white"
      }
      else if (value == 'assassin') {
        return 'black'
      }
    }
  }
  getTextColor(chosen: boolean, value: string) {

    if (!chosen) {
      return "black"
    }
    else if (chosen) {
      if (value == "neutral") {
        return "black"
      }
      else {
        return 'white'
      }
    }
  }
  tileClicked(event) {
    if (event[0][event[1]] == true) {   //we don't have to do anything if this tile was already clicked previously
      return;
    }
    let temp = [...event[0]];  //this copies current array and puts turns this tile status to true, to be passed to db later
    temp[event[1]] = true;

    let nextTurn = '';
    let color: string;
    let otherColor: string;

    if (this.role == "bluePlayer") {
      nextTurn = "redSpymaster"
      color = "blue";
    }
    else if (this.role == "redPlayer") {
      nextTurn = "blueSpymaster"
      color = "red";
    }

    let check = false;
    this.RefBoard.valueChanges().subscribe(data => {
      if (check) {
        return;
      }
      else {
        check = true;
      }

      let timeCheck = false;
      this.afs.collection<Timer>('Timer', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(timeData => {
        if (timeCheck) {
          return;
        }
        timeCheck = true;

        let timerUsed = true;
        if (timeData == undefined || timeData.length == 0) {
          timerUsed = false;
        }
        //all the data from the db we need to update it
        let value = data[0].values[event[1]];
        let redScore = data[0].redScore;
        let blueScore = data[0].blueScore;
        let clue = data[0].currClue;
        let word = data[0].words[event[1]];
        let history = data[0].history;
        let currGuesses = data[0].currGuesses;

        currGuesses.push({ 'word': word, 'value': value });

        if (value == "neutral") {
          history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })

          this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
            status: temp,
            turn: nextTurn,
            currClue: '',
            currGuesses: [],
            history: history
          })
          if (timerUsed) {
            this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
              lastUpdated: new Date().getTime(),
              turn: nextTurn,
              pausedTimeRemaining: timeData[0].spyTime
            })
          }
        }
        else if (value == "red" && this.role == "redPlayer") {
          if (redScore - 1 != 0) {
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              redScore: redScore - 1,
              currGuesses: currGuesses
            })
          } else {  //this is case where red will win, we want to update the history first
            history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              redScore: redScore - 1,
              currClue: '',
              currGuesses: [],
              history: history,
              redGamesWon: data[0].redGamesWon + 1,
              firstMatch: false
            })
            if (timerUsed) {
              this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
                'active': false
              })
            }
          }
        }
        else if (value == "red" && this.role == "bluePlayer") {
          let newRedScore = data[0].redGamesWon;
          let firstMatch = data[0].firstMatch;

          if (redScore - 1 == 0) {
            newRedScore++;
            firstMatch = false;
            if (timerUsed) {
              this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
                'active': false
              })
            }
          }
          history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
          this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
            status: temp,
            redScore: redScore - 1,
            turn: nextTurn,
            currClue: '',
            currGuesses: [],
            history: history,
            redGamesWon: newRedScore,
            firstMatch: firstMatch
          })
          if (timerUsed) {
            this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
              'lastUpdated': new Date().getTime(),
              turn: nextTurn,
              pausedTimeRemaining: timeData[0].spyTime
            })
          }
        }
        else if (value == "blue" && this.role == "bluePlayer") {
          if (blueScore - 1 != 0) {
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              blueScore: blueScore - 1,
              currGuesses: currGuesses
            })
          } else {  //this is case where blue will win, we want to update the history first
            history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              blueScore: blueScore - 1,
              currClue: '',
              currGuesses: [],
              history: history,
              blueGamesWon: data[0].blueGamesWon + 1,
              firstMatch: false
            })
            if (timerUsed) {
              this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
                'active': false
              })
            }
          }
        }
        else if (value == "blue" && this.role == "redPlayer") {
          let newBlueScore = data[0].blueGamesWon;
          let firstMatch = data[0].firstMatch;

          if (blueScore - 1 == 0) {
            newBlueScore++;
            firstMatch = false;
            if (timerUsed) {
              this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
                'active': false
              })
            }
          }
          history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
          this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
            blueScore: blueScore - 1,
            status: temp,
            turn: nextTurn,
            currClue: '',
            currGuesses: [],
            history: history,
            blueGamesWon: newBlueScore,
            firstMatch: firstMatch
          })
          if (timerUsed) {
            this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
              'lastUpdated': new Date().getTime(),
              turn: nextTurn,
              pausedTimeRemaining: timeData[0].spyTime
            })
          }
        }
        else if (value == "assassin") {

          if (timerUsed) {
            this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
              'active': false
            })
          }
          if (data[0].turn == "redPlayer") {
            history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              currClue: '',
              currGuesses: [],
              assassinHit: true,
              history: history,
              blueGamesWon: data[0].blueGamesWon + 1,
              firstMatch: false
            })
          }
          else {
            history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
            this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
              status: temp,
              currClue: '',
              currGuesses: [],
              assassinHit: true,
              history: history,
              redGamesWon: data[0].redGamesWon + 1,
              firstMatch: false

            })
          }
        }
      })
    })
  }
  endTurn() {

    let nextTurn = '';
    let color: string;

    if (this.role == "bluePlayer") {
      nextTurn = "redSpymaster";
      color = "blue";
    }
    else if (this.role == "redPlayer") {
      nextTurn = "blueSpymaster";
      color = "red";
    }
    let check = false;
    this.RefBoard.valueChanges().subscribe(data => {
      if (check) {
        return;
      }
      else {
        check = true;
      }
      let history = data[0].history;
      let currGuesses = data[0].currGuesses;
      let clue = data[0].currClue;

      history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })
      this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
        turn: nextTurn,
        currClue: '',
        currGuesses: [],
        history: history

      })
    })
    let timeCheck = false;
    this.afs.collection<Timer>('Timer', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(timeData => {
      if (timeCheck) {
        return;
      }
      timeCheck = true;

      let timerUsed = true;
      if (timeData == undefined || timeData.length == 0) {
        timerUsed = false;
      }
      if (timerUsed) {

        this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
          'lastUpdated': new Date().getTime(),
          'turn': nextTurn,
          pausedTimeRemaining: timeData[0].spyTime
        })
      }
    })

  }
}