import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { WordListsService } from '../word-lists.service';

interface Board {
  boardId: string;
  words: string[];
  values: string[];
  status: boolean[];
  turn: string;
  currClue: string;
  redScore: number;
  blueScore: number;
  assassinHit: boolean;
  isFamilyFriendly: boolean;
  redName : string;
  blueName: string;
  team1Color: string;
  team2Color: string;
}
interface Timer {
  boardId: string;
  lastUpdated: number;
  initialTime: number;
  spyTime: number;
  playerTime: number;
  active: boolean;
  turn: string;
  paused: boolean;
  pausedTimeRemaining: number;
}
@Component({
  selector: 'app-spymaster-board',
  templateUrl: './spymaster-board.component.html',
  styleUrls: ['./spymaster-board.component.css']
})
export class SpymasterBoardComponent implements OnInit {
  @Input() boardId: String;
  @Input() isParticipant: boolean;
  @Input() role: string;

  RefBoard: AngularFirestoreCollection<Board>;
  boards: Observable<Board[]>;
  constructor(private afs: AngularFirestore, private lists: WordListsService) { }
  words: string[];
  clue: string;

  redName: string;
  blueName: string;
  prompt: string;

  team1Color: string;
  team2Color: string;
  isFamilyFriendly: boolean;

  ngOnInit(): void {

    this.RefBoard = this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId));
    this.boards = this.RefBoard.valueChanges();

    this.boards.subscribe(data =>{
      this.redName = data[0].redName;
      this.blueName = data[0].blueName;

      this.team1Color = data[0].team1Color;
      this.team2Color = data[0].team2Color;
      this.isFamilyFriendly = data[0].isFamilyFriendly;
    })
  }

  getBackgroundColor(chosen: boolean, value:string){
   
    if(!chosen){
      if(value== 'assassin'){
        return 'dimgray	'
      }
      else{
        return 'grey'
      }
    }
    else if (chosen){
      if(value == "blue"){
        return this.team1Color
      }
      else if(value == "red"){
        return this.team2Color
      }
      else if(value == "neutral"){
        return "white"
      }
      else if(value == 'assassin'){
        return 'black'
      }
    }
  }
  getTextColor(chosen: boolean, value:string){
   
    if(!chosen){
      if(value== 'assassin'){
        return 'white	'
      }
      else if(value == 'blue'){
        return this.team1Color
      }
      else if(value == 'red'){
        return this.team2Color
      }
      else if(value == 'neutral'){
        return "black"
      }
    }
    else if (chosen){
      if(value == "neutral"){
        return "black"
      }
      else{
        return 'white'
      }
    }
  }
  Submit() {
    
    if (this.clue == undefined || this.clue == '') {
      alert("While some are capable of telepathy, let's assume your player is not. Give them a clue!");
      return;
    }
    if(this.isFamilyFriendly && this.lists.isWordNaughty(this.clue)){
      alert("Your entered clue includes a word/phrase that is banned. We're trying to keep the site family-friendly");
      return;
    }

    let nextTurn = '';
    if (this.role == "redSpymaster") {
      nextTurn = "redPlayer";
    }
    else {
      nextTurn = "bluePlayer";
    }
    this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf()).update({
      turn: nextTurn,
      currClue: this.clue
    }).finally(() => {
      this.clue = '';
    })

    let timeCheck = false;
    this.afs.collection<Timer>('Timer', ref => ref.where('boardId', '==', this.boardId)).valueChanges().subscribe(timeData => {
      if (timeCheck) {
        return;
      }
      timeCheck = true;

      let timerUsed = true;
      if (timeData == undefined || timeData.length == 0) {
        timerUsed = false;
      }
      if (timerUsed) {
        this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId)).doc(this.boardId.valueOf() + "_timer").update({
          'lastUpdated': new Date().getTime(),
          'turn': nextTurn,
          pausedTimeRemaining: timeData[0].playerTime
        })
      }
    })
  }

}