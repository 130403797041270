

import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { WordListsService } from './word-lists.service';
import {ThemePalette} from '@angular/material/core';
import { HttpClient } from '@angular/common/http';


interface Board {
  boardId: string
}
interface BannedIP {
  IPAddress: string;
  LinkedName: string;
  BannedDate: Date;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {


  createCustom: boolean;
  enteredId: string;
  refBoard: AngularFirestoreCollection<Board>;
  boards: Observable<Board[]>;
  background: ThemePalette = "accent"

  ipBannedData: BannedIP[];
  bannedIPRef: AngularFirestoreCollection<BannedIP>;

  ipAddress: string;
  banned:  boolean;
  verified: boolean; //is set once it has been confirmed that user is not banned

  constructor(private afs: AngularFirestore, private wordArchive: WordListsService, private http: HttpClient) { }

  ngOnInit() {
    this.banned = false;
    this.verified = false
    this.createCustom = false;

    this.getIPAddress()

    this.bannedIPRef = this.afs.collection('BannedIPs')

    this.bannedIPRef.valueChanges().subscribe(ipBanData => {
      this.ipBannedData = ipBanData;
      this.amIBanned();
      if(this.verified && this.banned){
        this.RedirectToTripAdvisory();
      }
    });
  }


  //on main app screen when they click the enter board, this will redirect to right board
  enterBoard() {
    if (this.enteredId == undefined) {
      alert("You must enter a board id");
      return;
    }

    this.afs.collection('Board', ref => ref.where('boardId', '==', this.enteredId)).valueChanges().subscribe(
      data => {
        if (data.length == 0) {
          alert("this board does not exist");
          return;
        }
        else {
          location.search = location.search ? '&id=' + this.enteredId : 'id=' + this.enteredId;
        }
      }
    );

  }

  createCustomBoard() {

    this.createCustom = true;
  }
  createRandomBoard() {
    let randomId = this.getUniqueId();  //randomly get board id

    let firstMove: string;

    let valueOptions: string[];
    let valueSelections: string[] = [];
    let wordOptions: string[] = this.wordArchive.normalWords;  //reference to appropriate array of all possible words, always normal words for random
    let wordSelections: string[] = [];  //this will be the current selection of words

    //randomly decide who goes first
    let blueScore: number;
    let redScore: number;

    let choice = Math.floor(Math.random() * 2);
    if (choice == 0) {
      valueOptions = ["red", "red", "red", "red", "red", "red", "red", "red",
        "blue", "blue", "blue", "blue", "blue", "blue", "blue", "blue", "blue",
        "neutral", "neutral", "neutral", "neutral", "neutral", "neutral", "neutral",
        "assassin"]
      firstMove = "blueSpymaster";
      redScore = 8;
      blueScore = 9;
    }
    else if (choice == 1) {
      valueOptions = ["red", "red", "red", "red", "red", "red", "red", "red", "red",
        "blue", "blue", "blue", "blue", "blue", "blue", "blue", "blue",
        "neutral", "neutral", "neutral", "neutral", "neutral", "neutral", "neutral",
        "assassin"]
      firstMove = "redSpymaster";
      redScore = 9;
      blueScore = 8;
    }
    let startingStatus = [false, false, false, false, false, false, false, false, false, false, false, false, false,
      false, false, false, false, false, false, false, false, false, false, false, false];

    //randomly chooses the words to be used
    while (wordSelections.length < 25) {
      let word = wordOptions[Math.floor(Math.random() * wordOptions.length)]
      if (!wordSelections.includes(word)) {  //make sure it wasn't already added
        wordSelections.push(word);
      }
    }
    //double randomly chooses which tile is assigned which value
    let positionsRemaining: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    for (let i = 0; i < 25; i++) {

      let valueIndex = Math.floor(Math.random() * valueOptions.length);
      let positionIndex = Math.floor(Math.random() * positionsRemaining.length);
      valueSelections[positionsRemaining[positionIndex]] = valueOptions[valueIndex];
      valueOptions.splice(valueIndex, 1);  //This just removes the appropriate element of array
      positionsRemaining.splice(positionIndex, 1);
    }
    this.afs.collection('Board').doc(randomId).set({
      'boardId': randomId, 'words': wordSelections,
      'values': valueSelections, 'status': startingStatus, 'turn': firstMove, 'currClue': '',
      'redScore': redScore, 'blueScore': blueScore, 'assassinHit': false, 'startMatch': false,
      'history': [], 'currGuesses': [], 'createDate': new Date().getTime(),
      'blueGamesWon': 0, 'redGamesWon': 0, 'firstMatch': true, 'prevGames': [], 'prevWinners': [],
      'isArchive': false, 'oldBlueScores': [], 'oldRedScores': [], 'restrictMembers': false,
      'redName': 'Red', 'blueName': 'Blue', 'includeAdmin': false, 'locked': false, 'accessCode': '',
      'team1Color': 'blue', 'team2Color': 'red', 'isFamilyFriendly': true, 'trackIPAddress': true

    }).finally(() => {
      location.search = location.search ? '&id=' + randomId : 'id=' + randomId;
    });
  }

  //this will return undefined/false if we have not gone to a specific page.
  //It will return the string/true of the id if we have
  get boardId(): string {
    let param = 'id'
    var match = RegExp('[?&]' + param + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  getUniqueId() {
    return Math.random().toString(36).substr(2, 8);
  }
  homePage(){
    location.search = '';
  }
  amIBanned() {
    this.banned = false;
    this.ipBannedData.forEach(ipEntry => {
      if (ipEntry.IPAddress == this.ipAddress)
        this.banned = true;
        return;
    })  
    this.verified = true;
  }
  getIPAddress() {

    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  RedirectToTripAdvisory(){
    window.location.href = "https://www.youtube.com/watch?v=0noiZWAZtyA"
  }
}
