<br>
<h3 align="center"><u>Create Custom board</u></h3>

<div style="display: block; text-align: center;">
  <form style="align-content: center">
    <input type="text" [(ngModel)]="requestedId" name="requestedId"
      placeholder="Desired Board Id (if left empty, random one will be assigned)">

      <font size=3>Exclusive to myHogwarts members? </font> &nbsp;<input type = "checkbox" (change) = "mHMatch = !mHMatch"/><br>
      <font size=3>Allow a user to have admin status?</font> &nbsp;<input [checked]= "allowAdmin" [disabled] = "disableAdminSelect" type = "checkbox" (change) = "allowAdmin = !allowAdmin"/><br>
      <font size=3>Family Friendly Match (ban inappropriate words)?</font> &nbsp;<input [checked]= "isFamilyFriendly" type = "checkbox" (change) = "isFamilyFriendly = !isFamilyFriendly"/><br>
      <font size=3>Track Users IP?</font> &nbsp;<input [checked]= "trackIP" type = "checkbox" (change) = "trackIP = !trackIP"/><br><br><hr>
      
      <font size=5>Provided Normal Words</font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="radio" id="normalWords" name="wordChoice" value="normal" [(ngModel)]="wordChoice"><br>
    <font size=5>Provided Harry Potter Words</font>&nbsp;
    <input type="radio" id="potterWords" name="wordChoice" value="potter" [(ngModel)]="wordChoice"><br>
    <font size=5>Provided myHogwarts Words</font>&nbsp;
    <input type="radio" id="mHWords" name="wordChoice" value="myHogwarts" [(ngModel)]="wordChoice"><br>
    <font size=5>Custom List</font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="radio" id="customWords" name="wordChoice" value="custom" [(ngModel)]="wordChoice">
    <input type = "text" name="words" [(ngModel)]="wordList"
      placeholder="This list requires at least 25 words seperated by commas">
    <hr>
    <br>
    <font size=3>Team 1 Color:&nbsp;</font>
    <select name = "team1Color" [(ngModel)] = "team1Color" (change) = "colorChange(1)">
      <option value="blue" [disabled] = "team2Color == 'blue'">blue</option>
      <option value="brown" [disabled] = "team2Color == 'brown'">brown</option>
      <option value="gold" [disabled] = "team2Color == 'gold'">gold</option>
      <option value="green" [disabled] = "team2Color == 'green'">green</option>
      <option value="hotpink" [disabled] = "team2Color == 'hotpink'">hot pink</option>
      <option value="orange" [disabled] = "team2Color == 'orange'">orange</option>
      <option value="pink" [disabled] = "team2Color == 'pink'">pink</option>
      <option value="purple" [disabled] = "team2Color == 'purple'">purple</option>
      <option value="red" [disabled] = "team2Color == 'red'">red</option>
      <option value="skyblue" [disabled] = "team2Color == 'skyblue'">sky blue</option>
      <option value="teal" [disabled] = "team2Color == 'teal'">teal</option>
      <option value="violet" [disabled] = "team2Color == 'violet'">violet</option>
      <option value="yellow" [disabled] = "team2Color == 'yellow'">yellow</option>

    </select>
    &nbsp;&nbsp;
      <font size=3>Team 2 Color:&nbsp;</font>
      <select name = "team2Color" [(ngModel)] = "team2Color" (change) = "colorChange(2)">
        <option value="blue" [disabled] = "team1Color == 'blue'">blue</option>
        <option value="brown" [disabled] = "team1Color == 'brown'">brown</option>
        <option value="gold" [disabled] = "team1Color == 'gold'">gold</option>
        <option value="green" [disabled] = "team1Color == 'green'">green</option>
        <option value="hotpink" [disabled] = "team1Color == 'hotpink'">hot pink</option>
        <option value="orange" [disabled] = "team1Color == 'orange'">orange</option>
        <option value="pink" [disabled] = "team1Color == 'pink'">pink</option>
        <option value="purple" [disabled] = "team1Color == 'purple'">purple</option>
        <option value="red" [disabled] = "team1Color == 'red'">red</option>
        <option value="skyblue" [disabled] = "team1Color == 'skyblue'">sky blue</option>
        <option value="teal" [disabled] = "team1Color == 'teal'">teal</option>
        <option value="violet" [disabled] = "team1Color == 'violet'">violet</option>
        <option value="yellow" [disabled] = "team1Color == 'yellow'">yellow</option>
    </select>
    <br><br>
    <font size=3>{{team1ColorDisplay}} Team Name:&nbsp;</font><textarea name = "blueName" [(ngModel)] = "blueName"  cols = "20" rows = "1">{{blueName}}</textarea>
    &nbsp;&nbsp;
    <font size=3>{{team2ColorDisplay}} Team Name:&nbsp;</font><textarea name = "redName" [(ngModel)] = "redName"  cols = "20" rows = "1">{{redName}}</textarea>
   <br> <br><font align="center" size=4>Team with first turn:</font><br><br>
    <font class ="colorTeam1" size=3>{{blueName}}:&nbsp;</font><input type="radio" id="blueTurn" name="firstMove" value="blue"
      [(ngModel)]="firstMove">&nbsp;&nbsp;
    <font class = "colorTeam2" size=3>{{redName}}:&nbsp;</font><input type="radio" id="redTurn" name="firstMove" value="red"
      [(ngModel)]="firstMove">&nbsp;&nbsp;
    <font size=3>Random:&nbsp;</font><input type="radio" id="randomTurn" name="firstMove" value="random"
      [(ngModel)]="firstMove">
    <br><br>
    <font size=3>Team with first turn # squares: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <textarea name = "openingSquares" [(ngModel)] = "openingSquares"  cols = "2" rows = "1">{{openingSquares}}</textarea>
    <br>
    <font size=3>Team with second turn # squares: </font>&nbsp;
    <textarea name = "secondarySquares" [(ngModel)] = "secondarySquares"  cols = "2" rows = "1">{{secondarySquares}}</textarea>
    <br>
    <font size=3># Neutral Squares: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <textarea name = "neutralSquares" [(ngModel)] = "neutralSquares"  cols = "2" rows = "1">{{neutralSquares}}</textarea>
    <br>
    <font size=3># Assassin Squares: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <textarea name = "assassinSquares" [(ngModel)] = "assassinSquares"  cols = "2" rows = "1">{{assassinSquares}}</textarea>
    <hr>
    <br>
    <font size=5>Use Timer: </font>&nbsp;<input type = "checkbox" (change) = "timerSelected()"/><br><br>
    <ng-container *ngIf = "useTimer">
      <font size=3>Initial Board Planning Time: </font>&nbsp;
      <textarea name = "initialMinutes" [(ngModel)] = "initialMinutes"  cols = "2" rows = "1">{{initialMinutes}}</textarea>
      &nbsp;: &nbsp;
      <textarea name = "initialSeconds" [(ngModel)] = "initialSeconds"  cols = "3" rows = "1">{{initialSeconds}}</textarea>
      <br>
      <font size=3>Spymaster turn time: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <textarea name = "spyMinutes" [(ngModel)] = "spyMinutes"  cols = "2" rows = "1">{{spyMinutes}}</textarea>
      &nbsp;: &nbsp;
      <textarea name = "spySeconds" [(ngModel)] = "spySeconds"  cols = "3" rows = "1">{{spySeconds}}</textarea>
      <br>
      <font size=3>Player turn time: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <textarea name = "playerMinutes" [(ngModel)] = "playerMinutes"  cols = "2" rows = "1">{{playerMinutes}}</textarea>
      &nbsp;: &nbsp;
      <textarea name = "playerSeconds" [(ngModel)] = "playerSeconds"  cols = "3" rows = "1">{{playerSeconds}}</textarea>
      <br><br><br>

    </ng-container>
    <input type="submit" value="Submit" (click)="Submit()">
  </form>
</div>