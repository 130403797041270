import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { WordListsService } from '../word-lists.service';

@Component({
  selector: 'app-customboard',
  templateUrl: './customboard.component.html',
  styleUrls: ['./customboard.component.css']
})
export class CustomboardComponent implements OnInit {

  constructor(private afs: AngularFirestore, private wordArchive: WordListsService) { }

  requestedId: string;  //optionally they can choose custom id for their board
  wordChoice: string;   //This is their choice if they want to use custom word list, or provided normal or Harry Potter or mH
  wordList: string;    //if they chose custom, this is where they provide their list of words
  firstMove: string;
  useTimer: boolean;
  initialMinutes: any;
  initialSeconds: any;
  spyMinutes: any;
  spySeconds: any;
  playerMinutes: any;
  playerSeconds: any;
  redName: string;
  blueName: string;
  mHMatch: boolean;
  allowAdmin: boolean;
  disableAdminSelect: boolean;
  isFamilyFriendly: boolean;
  trackIP: boolean;

  team1Color: string;
  team2Color: string;
  team1ColorDisplay: string;
  team2ColorDisplay: string;
  colorsList: string[];

  openingSquares: number;
  secondarySquares: number;
  neutralSquares: number;
  assassinSquares: number;

  ngOnInit(): void {
    this.useTimer = false;
    this.initialMinutes = 2;
    this.initialSeconds = 0;
    this.spyMinutes = 2;
    this.spySeconds = 0;
    this.playerMinutes = 2;
    this.playerSeconds = 0;
    this.blueName = "Blue";
    this.redName = "Red";
    this.mHMatch = false;
    this.allowAdmin = false;
    this.disableAdminSelect = false;
    this.isFamilyFriendly = false;
    this.trackIP = true;

    this.team1Color = "blue"
    this.team2Color = "red"
    this.team1ColorDisplay = "Blue"
    this.team2ColorDisplay = "Red"
    this.colorsList = ["blue", "brown", "gold", "green", "hot pink", "orange", "pink", "purple", "red", "sky blue", "teal", "violet", "yellow"]

    this.openingSquares = 9;
    this.secondarySquares = 8;
    this.neutralSquares = 7;
    this.assassinSquares = 1;
    

    //sets the color property of all elements that should be colored
    Array.prototype.forEach.call(document.getElementsByClassName('colorTeam1'), function (el) {
      el.color = "blue";
    });

    Array.prototype.forEach.call(document.getElementsByClassName('colorTeam2'), function (el) {
      el.color = "red";
    });

  }

  timerSelected() {
    this.useTimer = !this.useTimer
    if (this.useTimer) {
      alert("Note: An admin must be present at game for timer to function, turns will not progress when no admin is present.")
      this.allowAdmin = true
      this.disableAdminSelect = true;
    }
    else {
      this.disableAdminSelect = false;
    }
  }
  colorChange(teamNum: number) {

    if (teamNum == 1) {
      this.team1ColorDisplay = this.team1Color.charAt(0).toUpperCase() + this.team1Color.slice(1)
      switch (this.team1ColorDisplay) {
        case 'Skyblue':
          this.team1ColorDisplay = 'Sky Blue';
          break;
        case 'Hotpink':
          this.team1ColorDisplay = 'Hot Pink';
          break;
      }
      let color = this.team1Color
      //sets the color property of all elements that should be colored
      Array.prototype.forEach.call(document.getElementsByClassName('colorTeam1'), function (el) {
        el.color = color;
      });

    }
    if (teamNum == 2) {
      this.team2ColorDisplay = this.team2Color.charAt(0).toUpperCase() + this.team2Color.slice(1)
      switch (this.team2ColorDisplay) {
        case 'Skyblue':
          this.team2ColorDisplay = 'Sky Blue';
          break;
        case 'Hotpink':
          this.team2ColorDisplay = 'Hot Pink';
          break;
      }
      let color = this.team2Color
      //sets the color property of all elements that should be colored
      Array.prototype.forEach.call(document.getElementsByClassName('colorTeam2'), function (el) {
        el.color = color;
      });
    }
    if (teamNum == 1 && this.colorsList.indexOf(this.blueName.toLowerCase()) != -1) {  //only update team name if user hasn't manually entered another one yet

      this.blueName = this.team1Color.charAt(0).toUpperCase() + this.team1Color.slice(1);

      switch (this.blueName) {
        case 'Skyblue':
          this.blueName = 'Sky Blue';
          break;
        case 'Hotpink':
          this.blueName = 'Hot Pink';
          break;
      }
    }
    else if (teamNum == 2 && this.colorsList.indexOf(this.redName.toLowerCase()) != -1) {  //only update team name if user hasn't manually entered another one yet
      this.redName = this.team2Color.charAt(0).toUpperCase() + this.team2Color.slice(1);

    }
    switch (this.redName) {
      case 'Skyblue':
        this.redName = 'Sky Blue';
        break;
        case 'Hotpink':
          this.redName = 'Hot Pink';
    }
  }
  Submit() {

    if (this.wordChoice == undefined) {
      alert("You must choose an option for type of words to use");
      return;
    }

    if (this.wordChoice == "custom" && (this.wordList == undefined || this.wordList == '')) {
      alert("Must provide a list of words if you choose custom list");
      return;
    }
    if (this.firstMove == undefined) {
      alert("Please choose an option for who moves first");
      return;
    }

    if (this.redName == '' || this.blueName == '') {
      alert('Cannot have a blank team name');
      return;
    }

    if (isNaN(this.openingSquares) || isNaN(this.secondarySquares) ||
      isNaN(this.assassinSquares) || isNaN(this.neutralSquares) || this.openingSquares <= 0 || this.secondarySquares <= 0 ||
      this.neutralSquares < 0 || this.assassinSquares < 0
    ) {
      alert("All selections for number of squares for each category must be positive numbers (assasin/neutral can be 0)");
      return;
    }
    if (25 - this.openingSquares - this.secondarySquares - this.assassinSquares - this.neutralSquares != 0) {
      alert("Selection for number of squares for each category must add to 25");
      return;
    }
    if (this.useTimer) {
      if (isNaN(this.initialMinutes) || isNaN(this.initialSeconds) ||
        isNaN(this.spyMinutes) || isNaN(this.spySeconds) ||
        isNaN(this.playerMinutes) || isNaN(this.playerSeconds)
      ) {
        alert("invalid time input.  They must all be numbers");
        return;
      }
      else if (!(this.initialMinutes <= 9 && this.initialMinutes >= 0) ||
        !(this.spyMinutes <= 9 && this.spyMinutes >= 0) ||
        !(this.playerMinutes <= 9 && this.playerMinutes >= 0) ||
        this.initialMinutes % 1 != 0 || this.spyMinutes % 1 != 0 || this.playerMinutes % 1 != 0) {
        alert("One of timer minutes input is invalid.  Must be between 0 and 9 and be whole number");
        return;
      }
      else if (!(this.initialSeconds <= 59 && this.initialSeconds >= 0) ||
        !(this.spySeconds <= 59 && this.spySeconds >= 0) ||
        !(this.playerSeconds <= 59 && this.playerSeconds >= 0) ||
        this.initialSeconds % 1 != 0 || this.spySeconds % 1 != 0 || this.playerSeconds % 1 != 0) {
        alert("One of timer seconds input is invalid.  Must be between 0 and 59 and be whole number");
        return;
      }
    }
    //this makes sure below logic only checks condition once, seeing as it'll technically execute twice once new board is added
    let check: boolean = false;

    if (this.requestedId == undefined || this.requestedId == '') {
      this.requestedId = this.getUniqueId();
    }
    //This makes sure decided upon board id is not already in use before 
    this.afs.collection('Board', ref => ref.where('boardId', '==', this.requestedId)).valueChanges().subscribe(
      data => {

        if (data.length == 0 && !check) {    //here we'll randomly pick words and their positions
          check = true;
          let valueOptions: string[] = []
          let blueScore: number;
          let redScore: number;


          if (this.firstMove == "blue") {
            this.firstMove = "blueSpymaster"
            blueScore = this.openingSquares;
            redScore = this.secondarySquares;
          }
          else if (this.firstMove == "red") {
            this.firstMove = "redSpymaster";
            blueScore = this.secondarySquares;
            redScore = this.openingSquares;
          }
          else {    //they chose random first move
            let choice = Math.floor(Math.random() * 2);
            if (choice == 0) {
              this.firstMove = "blueSpymaster";
              blueScore = this.openingSquares;
              redScore = this.secondarySquares;
            }
            else if (choice == 1) {
              this.firstMove = "redSpymaster";
              redScore = this.openingSquares;
              blueScore = this.secondarySquares;
            }
          }
          for (let i = 0; i < this.assassinSquares; i++) {
            valueOptions.push("assassin");
          }
          for (let i = 0; i < this.neutralSquares; i++) {
            valueOptions.push("neutral");
          }
          for (let i = 0; i < blueScore; i++) {
            valueOptions.push("blue");
          }
          for (let i = 0; i < redScore; i++) {
            valueOptions.push("red");
          }
          let valueSelections: string[] = [];
          let wordOptions: string[];  //reference to appropriate array of all possible words
          let wordSelections: string[] = [];  //this will be the current selection of words

          if (this.wordChoice == "normal") {
            wordOptions = this.wordArchive.normalWords;
          }
          else if (this.wordChoice == "potter") {
            wordOptions = this.wordArchive.potterWords;
          }
          else if (this.wordChoice == "myHogwarts") {
            wordOptions = this.wordArchive.mHWords;
          }
          else {    //we have custom list....yeah?

            wordOptions = this.wordList.split(",");

            if (wordOptions.length < 25) {
              alert("not enough words were provided");
              return;
            }
          }
          while (wordSelections.length < 25) {
            let word = wordOptions[Math.floor(Math.random() * wordOptions.length)]
            if (!wordSelections.includes(word)) {  //make sure it wasn't already added
              wordSelections.push(word);
            }
          }
          //this is another step to help simulate real randomness

          let positionsRemaining: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
          for (let i = 0; i < 25; i++) {

            let valueIndex = Math.floor(Math.random() * valueOptions.length);
            let positionIndex = Math.floor(Math.random() * positionsRemaining.length);
            valueSelections[positionsRemaining[positionIndex]] = valueOptions[valueIndex];
            valueOptions.splice(valueIndex, 1);  //This just removes the appropriate element of array
            positionsRemaining.splice(positionIndex, 1);
          }
          //at start, no tiles have been clicked
          let startingStatus = [false, false, false, false, false, false, false, false, false, false, false, false, false,
            false, false, false, false, false, false, false, false, false, false, false, false];



          if (this.useTimer) {
            let startingTeam = this.firstMove
            this.firstMove = "boardPreview"

            let timerId = this.requestedId + "_timer";
            this.afs.collection('Timer').doc(timerId).set({
              'lastUpdated': 0, 'initialTime': (Number(this.initialMinutes * 60) + Number(this.initialSeconds)),
              'spyTime': (Number(this.spyMinutes * 60) + Number(this.spySeconds)), 'playerTime': (Number(this.playerMinutes * 60) + Number(this.playerSeconds)),
              'active': false, 'boardId': this.requestedId, 'turn': 'boardPreview', 'firstMove': startingTeam,
              'paused': false, 'pausedTimeRemaining': (Number(this.initialMinutes * 60) + Number(this.initialSeconds))
            });

          }
          this.afs.collection('Board').doc(this.requestedId).set({
            'boardId': this.requestedId, 'words': wordSelections,
            'values': valueSelections, 'status': startingStatus, 'turn': this.firstMove, 'currClue': '',
            'redScore': redScore, 'blueScore': blueScore, 'assassinHit': false, 'startMatch': false,
            'history': [], 'currGuesses': [], 'createDate': new Date().getTime(),
            'blueGamesWon': 0, 'redGamesWon': 0, 'firstMatch': true, 'prevGames': [], 'prevWinners': [],
            'isArchive': false, 'oldBlueScores': [], 'oldRedScores': [], 'restrictMembers': this.mHMatch,
            'redName': this.redName, 'blueName': this.blueName, 'includeAdmin': this.allowAdmin, 'locked': false,
            'accessCode': '', 'team1Color': this.team1Color, 'team2Color': this.team2Color, 'isFamilyFriendly': this.isFamilyFriendly,
            'trackIPAddress': this.trackIP
          }).finally(() => {

            location.search = location.search ? '&id=' + this.requestedId : 'id=' + this.requestedId;
          });
        }
        else if (!check) {
          check = true;
          alert("A board with this id already exists.  If you did not enter one, just try again.");
          return;
        }
      }
    );


  }
  getUniqueId() {
    return Math.random().toString(36).substr(2, 8);
  }
}
