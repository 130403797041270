import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

interface Lists {
  HPWordList: string[];
  NormalList: string[];
  mHUserList: string[];
  mHWordList: string[];
  NaughtyWordList: string[];
  Admin_Login: {Username: string, Password: string}
}

@Injectable({
  providedIn: 'root'
})
export class WordListsService {

  
  mHNameList: string[]
  normalList: string[]
  HPList: string[]
  mHWordList: string[]
  naughtyWordList: string[]

  
  RefLists: AngularFirestoreCollection<Lists>;

  constructor(private afs: AngularFirestore) {
   
    this.RefLists = this.afs.collection('Lists')

    this.RefLists.valueChanges().subscribe(data => {
      this.mHNameList = data[0].mHUserList
      this.normalList = data[0].NormalList
      this.HPList = data[0].HPWordList
      this.naughtyWordList = data[0].NaughtyWordList
      this.mHWordList = data[0].mHWordList
    })
  }
  get normalWords() {
    return this.normalList;
  }
  get potterWords() {
    return this.HPList;
  }
  get mHWords() {
    return this.mHWordList;
  }
  get userList(){
    let nameString =  this.mHNameList.toString()
    nameString = nameString.toLowerCase()
    return nameString.split(',')
  }

  isWordNaughty(word: string):boolean{

    let naughtyString =  this.naughtyWordList.toString()
    let naughtyArray = naughtyString.toLowerCase().split(',')
    let foundNaughtyMatch = false;

    naughtyArray.forEach(naughtyWord => {

     if(word.toLowerCase().indexOf(naughtyWord) != -1){
        foundNaughtyMatch =  true;
        return;
      }
    })
   return foundNaughtyMatch;
  }
}
