&nbsp;&nbsp;
<div *ngIf="!loggedin">
    <div style="display: block; text-align: center;overflow:hidden">
        <form>
            <input type="text" [(ngModel)]="username" name="username" placeholder="username" />
            <input type="text" [(ngModel)]="password" name="password" placeholder="password" />

            <input type="submit" value="Enter" (click)="login()">
            <br><br>
        </form>
    </div>
</div>

<div *ngIf="loggedin">
    <br>
    <mat-tab-group mat-stretch-tabs [backgroundColor]="background">
        <mat-tab label="List Maintenance">

            <br><br>

            <div style="display: block; text-align: center;overflow:hidden">
                <font size=3>mH Member Name List:</font><br>
                <textarea name="mHNameList" [(ngModel)]="mHNameList" cols="100" rows="20">{{mHNameList}}</textarea>
                &nbsp;&nbsp;
                <input type="submit" value="Submit mH User List" (click)="confirmusers()">

                <br><br>
                <font size=3>Normal Word List:</font><br>
                <textarea name="normalList" [(ngModel)]="normalList" cols="100" rows="20">{{normalList}}</textarea>
                &nbsp;&nbsp;
                <input type="submit" value="Submit Normal Word List" (click)="confirmNormal()">

                <br><br>
                <font size=3>Harry Potter Word List:</font><br>
                <textarea name="HPList" [(ngModel)]="HPList" cols="100" rows="20">{{HPList}}</textarea>
                &nbsp;&nbsp;
                <input type="submit" value="Submit Harry Potter Word List" (click)="confirmPotter()">

                <br><br>
                <font size=3>mH Word List:</font><br>
                <textarea name="mHWordList" [(ngModel)]="mHWordList" cols="100" rows="20">{{mHWordList}}</textarea>
                &nbsp;&nbsp;
                <input type="submit" value="Submit mH Word List" (click)="confirmmH()">

                <br><br>
                <font size=3>Naughty Word List</font><br>
                <font size=1> (Banned words that are are prevented from being typed by users anywhere)</font><br>
                <textarea name="naughtyWordList" [(ngModel)]="naughtyWordList" cols="100"
                    rows="20">{{naughtyWordList}}</textarea>
                &nbsp;&nbsp;
                <input type="submit" value="Submit Naughty Word List" (click)="naughtyConfirmation()">
            </div>
        </mat-tab>
        <mat-tab label="User IP Address Management">
            <br><br>

            <u>Some notes on Below IP Usage:</u><br><br>
            -This is merely intended to be a reference to compare with IP data stored on the myHogwarts board to help
            determine what members are using the site inappropriately<br><br>
            -Below is IPv4 addresses specifically, I doubt we'd need IPv6 but that can be added if needed as
            well<br><br>
            -mH admin are familiar with this I'm sure, but what's given below is the public IP address that is being
            used to connect to the site. This does not give the IP of the device itself but is useful
            as it still gives an address that will almost always be specific enough to identify the mH user responsible
            given that the users are from around the world and using different service providers (example
            of where this would not be the case is if two siblings living in same house were to connect. Assuming they
            were using the same wifi, both of their ip addresses would almost certainly be identical below ).
            With that in mind, it's worth being a bit cautious and understand the limits of what's given below;
            naturally things like VPNs can change the address and there's no <i>gurantee</i> that public adresses even
            would stay
            consistent. Though this should serve as a good reference as I imagine myHogwarts itself would be storing the
            same thing (but that I'm not sure about)<br><br>
            -Banning is really meant as a temporary measure for these reasons as well, it literally would just prevent
            that IP from connecting and getting around
            this is very doable. With that said, given the level of sophistication that the people that we may want to
            ban seem to deploy, most likely this would be enough to stump them<br><br>
            -This data will only be guranteed to be stored for a month (as I can't have it build up forever without
            clearing it) but that should give plenty of time to lookup an address if there was an issue. The list of
            banned IPs will obviously not be cleared though<br><br>

            <hr>
            <br>
            <div align="center">
                <h2>Recent IP Address History</h2>
            </div><br>
            <table>
                <tr>
                    <th><u>Name Used</u></th>
                    <th><u>BoardID</u></th>
                    <th><u>Date</u></th>
                    <th><u>IP Address</u></th>
                </tr>
                <ng-container *ngFor="let ipEntry of ipHistoryData">
                    <tr>
                        <td>{{ ipEntry.Name }}</td>
                        <td>{{ ipEntry.BoardID }}</td>
                        <td>{{ ipEntry.Date }}</td>
                        <td>{{ ipEntry.IPAddress }}</td>
                        <ng-container *ngIf="!isIPBanned(ipEntry.IPAddress) && ipEntry.IPAddress != 'Login Rejected - IP not available'">
                            <td><button (click)="banIP(ipEntry)"
                                    style="background-color: red; color: white; cursor: pointer">Ban</button></td>
                        </ng-container>
                        <ng-container *ngIf="isIPBanned(ipEntry.IPAddress)">
                            <td><button (click)="unBanIP(ipEntry.IPAddress, ipEntry.Name)"
                                style="background-color: green; color: white; cursor: pointer">Unban</button></td>
                    
                        </ng-container>
                    </tr>
                </ng-container>
            </table>
            <br>
            <hr>
            <div align="center">
                <h2>Currently Banned IP Addresses</h2>
            </div>
            <table>
                <tr>
                    <th><u>IP Address</u></th>
                    <th><u>Associated Name</u></th>
                    <th><u>Date of Ban</u></th>
                </tr>
                <ng-container *ngIf="ipBannedData.length != 0">
                    <ng-container *ngFor="let ipBanEntry of ipBannedData">
                        <tr>
                            <td>{{ ipBanEntry.IPAddress }}</td>
                            <td>{{ ipBanEntry.LinkedName }}</td>
                            <td>{{ ipBanEntry.BannedDate }}</td>
                            <td><button (click)="unBanIP(ipBanEntry.IPAddress, ipBanEntry.LinkedName)"
                                    style="background-color: green; color: white; cursor: pointer">Unban</button></td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
            <ng-container *ngIf="ipBannedData.length == 0">
                <br>
                <div align="center">There are currently no IPs that are banned</div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div><br><br>