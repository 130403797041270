import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

interface Board {
  boardId: string;
  words: string[];
  values: string[];
  status: boolean[];
  turn: string;
  currClue: string;
  redScore: number;
  blueScore: number;
  assassinHit: boolean;
  currGuesses: any[];
  history: any[];
  isArchive: boolean;
  oldRedScores: number[];
  oldBlueScores: number[];
  timerStarted: number;

  team1Color: string;
  team2Color: string;
}

interface Timer {
  boardId: string;
  lastUpdated: number;
  initialTime: number;
  spyTime: number;
  playerTime: number;
  active: boolean;
  turn: string;
  firstMove: string;
  paused: boolean;
  pausedTimeRemaining: number;
}

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  @Input() boardId: String;
  @Input() role: String;
  @Input() isAdmin: String;

  BoardRef: AngularFirestoreCollection<Board>;
  TimerRef: AngularFirestoreCollection<Timer>;
  timer: Observable<Timer[]>;

  minutesDisplay: number;
  secondsDisplay: any;
  interval;
  nextTurn;
  timerVisible: boolean;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {

    this.timerVisible = true;

    this.BoardRef = this.afs.collection('Board', ref => ref.where('boardId', '==', this.boardId));
    this.TimerRef = this.afs.collection('Timer', ref => ref.where('boardId', '==', this.boardId));
    this.timer = this.TimerRef.valueChanges();

    this.TimerRef.valueChanges().subscribe(data => {

      if (data.length == 0) {
        return
      }
      if (data[0].active == false) {
        clearInterval(this.interval);
        return;
      }
      if (data[0].paused) {

        this.minutesDisplay = Math.floor(data[0].pausedTimeRemaining / 60);
        this.secondsDisplay = data[0].pausedTimeRemaining % 60 >= 10 ? Math.floor(data[0].pausedTimeRemaining % 60) : "0" + Math.floor(data[0].pausedTimeRemaining % 60);
        clearInterval(this.interval);
      }
      else {
        if (data[0].turn == "boardPreview") {
          this.updateTimer(data[0].lastUpdated, data[0].initialTime, data[0].turn, data[0].firstMove);
        }
        else if (data[0].turn == "redSpymaster" || data[0].turn == "blueSpymaster") {
          this.updateTimer(data[0].lastUpdated, data[0].spyTime, data[0].turn, data[0].firstMove);
        }
        else if (data[0].turn == "redPlayer" || data[0].turn == "bluePlayer") {
          this.updateTimer(data[0].lastUpdated, data[0].playerTime, data[0].turn, data[0].firstMove);
        }
      }
    });
  }
  updateTimer(startTime: number, intervalTime: number, turn: string, firstMove: string) {

    clearInterval(this.interval);
    let secondsLeft = intervalTime - (new Date().getTime() - startTime) / 1000;
    let updating = false;

    this.interval = setInterval(() => {


      if (secondsLeft > 0) {
        this.minutesDisplay = Math.floor(secondsLeft / 60);
        this.secondsDisplay = secondsLeft % 60 >= 10 ? Math.floor(secondsLeft % 60) : "0" + Math.floor(secondsLeft % 60);   //this appends 0 if seconds are single digit
        secondsLeft--;
      } else {       //we've hit 0 and need to change the turn of board
        clearInterval(this.interval);
        if (updating == false && this.isAdmin) {
          updating = true;
          let color;

          let nextTurn: string = '';

          if (turn == "boardPreview") {

            if (firstMove == 'redSpymaster') {
              nextTurn = 'redSpymaster';
            }
            else {
              nextTurn = 'blueSpymaster';
            }
          }
          else if (turn == "redSpymaster") {
            nextTurn = "redPlayer"
          }
          else if (turn == "blueSpymaster") {
            nextTurn = "bluePlayer"
          }
          else if (turn == "redPlayer") {
            nextTurn = "blueSpymaster"
          }
          else if (turn == "bluePlayer") {
            nextTurn = "redSpymaster"
          }

          let check = false;
          this.BoardRef.valueChanges().subscribe(data => {
            if (check) {
              return;
            }
            check = true;
            
            if (turn == "bluePlayer" || turn == "redPlayer") {
              let history = data[0].history;
              let currGuesses = data[0].currGuesses;
              let clue = data[0].currClue;
              let color;

              if (turn == "bluePlayer") {
                color = "blue"
              }
              else if (turn == "redPlayer") {
                color = "red";
              }

              history.push({ 'clue': clue, 'color': color, 'guesses': currGuesses })

              this.afs.collection('Board').doc(this.boardId.valueOf()).update({
                'turn': nextTurn,
                'currClue': "",
                'currGuesses': [],
                'history': history
              })
            }
            else {
              this.afs.collection('Board').doc(this.boardId.valueOf()).update({
                'turn': nextTurn,
                'currClue': "Spymaster is banking on telepathy",
              })
            }
            this.afs.collection('Timer').doc(this.boardId.valueOf() + "_timer").update({
              'lastUpdated': new Date().getTime(),
              'turn': nextTurn
            });
          })
        }
        return
      }
    }, 1000)
  }
  hideTimer() {
    this.timerVisible = false;
  }
  showTimer() {
    this.timerVisible = true;
  }
}
