<div style="text-align: center;">
    <ng-container *ngFor="let clock of timer| async">
        <ng-container *ngIf="clock.active">
            <ng-container *ngIf="timerVisible">
                <p>
                    <font color="white" size=6>{{minutesDisplay}}:{{secondsDisplay}}</font>
                </p>


                <button (click)="hideTimer()">Hide Timer</button>
            </ng-container>
            <ng-container *ngIf="!timerVisible">
               <br> <button (click)="showTimer()">Show Timer</button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>