<ng-container *ngIf="verified && !banned">
  <div align="center" style="margin-top:30px; cursor: pointer; font-family: Gruppo; font-size: 50px;"
    (click)=homePage()>
    Codenames!</div>
  <div align="center" style="margin-top:0px; font-family: 'Brush Script MT', 'Brush Script Std', 'cursive'">Specially
    brewed for myHogwarts.com
  </div>
  <br><br>
  <mat-tab-group mat-stretch-tabs [backgroundColor]="background">
    <mat-tab label="Play!">
      <br><br>
      <div *ngIf="!boardId && !createCustom" style="overflow:hidden">
        <input type="text" [(ngModel)]="enteredId" name="id" placeholder="Enter Board Code">
        <input type="submit" value="Go to board" (click)="enterBoard()">
        <br><br><br><br>
        <hr>
        <p align="center">If board not already created, choose one of following to generate one</p>
        <input type="submit" value="Create custom board" (click)="createCustomBoard()"><br>
        <input type="submit" value="Generate random board" (click)="createRandomBoard()">
      </div>
      <div *ngIf="createCustom" style="overflow:hidden">
        <app-customboard></app-customboard>
      </div>
      <div style="overflow:hidden" *ngIf="boardId">
        <app-maingame></app-maingame>
      </div>
      <br><br><br><br><br><br><br><br>
    </mat-tab>

    <mat-tab label="Game/Site Rules">
      <app-rules></app-rules>
    </mat-tab>
    <mat-tab label="Admin Control Center">

      <app-maint></app-maint>

    </mat-tab>
    <mat-tab label="Developer">
      <br><br>
      <p align="center">Developed by Kevin Fischer, a software engineer currently residing in Minneapolis, MN.</p>

    </mat-tab>
  </mat-tab-group>
</ng-container>
