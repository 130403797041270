import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AngularFireModule} from 'angularfire2'
import{AngularFirestoreModule} from 'angularfire2/firestore'
import{AngularFireDatabaseModule} from '@angular/fire/database';
import{AngularFireAuthModule} from '@angular/fire/auth';

import{FormsModule} from  '@angular/forms';
import { CustomboardComponent } from './customboard/customboard.component';
import { MaingameComponent } from './maingame/maingame.component';
import { PlayerBoardComponent } from './player-board/player-board.component';
import { SpymasterBoardComponent } from './spymaster-board/spymaster-board.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { RulesComponent } from './rules/rules.component';
import { TimerComponent } from './timer/timer.component';
import { MaintComponent } from './maint/maint.component';
import { HttpClientModule } from '@angular/common/http';

var firebaseConfig = {
  apiKey: "AIzaSyB6KNKLHYjwW-dmHua65wh1Kp4oap0XyUg",
  authDomain: "codenames-4c8b9.firebaseapp.com",
  databaseURL: "https://codenames-4c8b9.firebaseio.com",
  projectId: "codenames-4c8b9",
  storageBucket: "codenames-4c8b9.appspot.com",
  messagingSenderId: "849002130285",
  appId: "1:849002130285:web:acded1b04b69442e96c864",
  measurementId: "G-VMYVC4C637"
};

@NgModule({
  declarations: [
    AppComponent,
    CustomboardComponent,
    MaingameComponent,
    PlayerBoardComponent,
    SpymasterBoardComponent,
    RulesComponent,
    TimerComponent,
    MaintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FormsModule,
    NoopAnimationsModule,
    MatTabsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
