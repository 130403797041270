<div *ngIf="validBoard">
  <u>
    <p style="text-align:left;">
      <font [color]="team1Color" size=6>{{blueName}}</font> <span style="float:right; text-decoration:underline">
        <font [color]="team2Color" size=6>{{redName}}</font>
      </span>
    </p>
  </u>
  <p style="text-align:left; white-space: nowrap;">

    <ng-container *ngFor="let spy of blueSpymaster| async">
      <ng-container *ngIf="spy.isAdmin">
        <font [color]="team1Color" size=5>Spymaster:&nbsp;<u>{{spy.Name}}</u></font>
      </ng-container>
      <ng-container *ngIf="!spy.isAdmin">
        <font [color]="team1Color" size=5>Spymaster:&nbsp;{{spy.Name}}</font>
        <ng-container *ngIf="isAdmin && loggedIn">
          <font style="cursor: pointer" color="grey" size=5 (click)=removeUser(spy.Name)>&nbsp;X</font>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="spy.Name == name">
        <font [color]="team1Color" size=5>*</font>
      </ng-container>
    </ng-container>

    <span style="float:right;">
      <ng-container *ngFor="let spy of redSpymaster| async">
        <ng-container *ngIf="spy.isAdmin">
          <font [color]="team2Color" size=5>Spymaster:&nbsp; <u>{{spy.Name}}</u></font>
        </ng-container>
        <ng-container *ngIf="!spy.isAdmin">
          <font [color]="team2Color" size=5>Spymaster:&nbsp; {{spy.Name}}</font>
          <ng-container *ngIf="isAdmin && loggedIn">
            <font style="cursor: pointer" color="grey" size=5 (click)=removeUser(spy.Name)>&nbsp;X</font>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="spy.Name == name">
          <font [color]="team2Color" size=5>*&nbsp;</font>
        </ng-container>
        <ng-container *ngIf="spy.Name != name">
          <font [color]="team2Color" size=5>&nbsp;</font>
        </ng-container>
      </ng-container>
    </span>
    <div style="text-align:left; white-space: nowrap;">
      <ng-container *ngFor="let player of bluePlayer| async">
        <ng-container *ngIf="player.isAdmin">
          <font [color]="team1Color" size=5>Player: &nbsp; <u>{{player.Name}}</u></font>
        </ng-container>
        <ng-container *ngIf="!player.isAdmin">
          <font [color]="team1Color" size=5>Player: &nbsp; {{player.Name}}</font>
          <ng-container *ngIf="isAdmin && loggedIn">
            <font style="cursor: pointer" color="grey" size=5 (click)=removeUser(player.Name)>&nbsp;X</font>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="player.Name == name">
          <font [color]="team1Color" size=5>*&nbsp;</font>
        </ng-container>
      </ng-container>
      <span style="float:right;">
        <ng-container *ngFor="let player of redPlayer| async">
          <ng-container *ngIf="player.isAdmin">
            <font [color]="team2Color" size=5>Player:&nbsp;<u>{{player.Name}}</u></font>
          </ng-container>
          <ng-container *ngIf="!player.isAdmin">
            <font [color]="team2Color" size=5>Player:&nbsp; {{player.Name}}</font>
            <ng-container *ngIf="isAdmin && loggedIn">
              <font style="cursor: pointer" color="white" size=5 (click)=removeUser(player.Name)>&nbsp;X</font>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="player.Name == name">
            <font [color]="team2Color" size=5>*&nbsp;</font>
          </ng-container>
          <ng-container *ngIf="player.Name != name">
            <font [color]="team2Color" size=5>&nbsp;</font>
          </ng-container>
        </ng-container>
      </span>
      <div style="text-align:left; white-space: nowrap;">
        <ng-container *ngFor="let board of boards| async">
          <font [color]="team1Color" size=14> {{board.blueScore}} </font>
          <span style="float:right;">
            <font [color]="team2Color" size=14> {{board.redScore}}&nbsp;</font>
          </span>
          <ng-container *ngIf="board.firstMatch == false">
            <br>
            <font [color]="team1Color" size=3> Games won:&nbsp; {{board.blueGamesWon}} </font>
            <span style="float:right;">
              <font [color]="team2Color" size=3>Games won: &nbsp;{{board.redGamesWon}}&nbsp;</font>
            </span>
            <br>
          </ng-container>
        </ng-container>

        <br>
        <ng-container *ngIf="loggedIn && isAdmin">
          <ng-container *ngFor="let board of boards| async">
            <br>
            <ng-container *ngIf="!board.locked">
              <input type="submit" value="Lock Game" (click)="LockGame()">
            </ng-container>
            <ng-container *ngIf="board.locked">
              <h3 align="center">Give this access code to allow new people to join:</h3>
              <h3 align="center">{{board.accessCode}}</h3>
              <input type="submit" value="Unlock Game" (click)="UnlockGame()">
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let clock of timer| async">
            <ng-container *ngIf="clock.active">
              <br>
              <ng-container *ngIf="!clock.paused">
                <input type="submit" value="Pause Timer" (click)="pauseTimer()">
              </ng-container>
              <ng-container *ngIf="clock.paused">
                <input type="submit" value="Restart Timer" (click)="restartTimer()">
              </ng-container>

              <input type="submit" value="Remove Timer" (click)="removeTimer()">
            </ng-container>

          </ng-container>
        </ng-container>
        <br>
        <div *ngIf="!loggedIn">
          <ng-container *ngFor="let board of boards| async">
            <ng-container *ngIf="!board.locked || accessGranted">
              <div style="display: block; text-align: center;">
                <form style="align-content: center; display: inline-block">
                  <input type="text" [(ngModel)]="name" name="name" placeholder="Name (mH username)" />
                  <ng-container *ngIf="board.includeAdmin">
                    <font size=3>Include Admin priviledges?</font> &nbsp;<input type="checkbox"
                      (change)="isAdmin = !isAdmin" /><br><br>
                    <hr>
                  </ng-container>
                  <tr style="text-align: center;">
                    <span [ngClass]="{ 'largeName': blueName.length>20, 'smallName': blueName.length<=20}">
                      <font [color]="team1Color"> {{blueName}} Spymaster &nbsp; </font>
                    </span>
                    <input type="radio" id="blueSpymaster" name="role" style="position: absolute; right: 38%"
                      value="blueSpymaster" [(ngModel)]="role" />
                  </tr>
                  <tr>
                    <span [ngClass]="{ 'largeName': blueName.length>20, 'smallName': blueName.length<=20}">
                      <font [color]="team1Color">{{blueName}} Player</font>
                    </span><input type="radio" style="position: absolute; right: 38%" id="bluePlayer" name="role"
                      value="bluePlayer" [(ngModel)]="role" />
                  </tr>
                  <tr>
                    <span [ngClass]="{ 'largeName': redName.length>20, 'smallName': redName.length<=20}">
                      <font [color]="team2Color">{{redName}} Spymaster</font>
                    </span>
                    <input type="radio" style="position: absolute; right: 38%" id="redSpymaster" name="role"
                      value="redSpymaster" [(ngModel)]="role" />
                  </tr>
                  <tr>
                    <span [ngClass]="{ 'largeName': redName.length>20, 'smallName': redName.length<=20}">
                      <font [color]="team2Color"> {{redName}} Player </font>
                    </span>
                    <input type="radio" style="position: absolute; right: 38%" id="redPlayer" name="role"
                      value="redPlayer" [(ngModel)]="role" />
                  </tr>
                  <tr>
                    <font size=5>Spectator </font><input style="position: absolute; right: 38%" type="radio"
                      id="spectator" name="role" value="spectator" [(ngModel)]="role" />
                  </tr>
                  <input type="submit" value="Enter" (click)="Login()">
                  <br><br>
                </form>
              </div>
            </ng-container>
            <ng-container *ngIf="board.locked && !accessGranted">
              <p align="center">This board was locked by the matches' admin. Request access code for them and enter
                below:</p>

              <br>
              <input type="text" [(ngModel)]="accessCode" name="access" placeholder="Access Code" />
              <input type="submit" value="Enter" (click)="requestAccess()">

            </ng-container>
          </ng-container>
        </div>
        <div *ngIf="loggedIn ">

          <app-timer [boardId]="boardId" [role]="role" [isAdmin]="isAdmin"></app-timer>

          <mat-tab-group mat-stretch-tabs [backgroundColor]="background">

            <mat-tab label="Live Board">
              <ng-container *ngFor="let board of boards| async">
                <ng-container *ngIf="board.startMatch">
                  <br>
                  <ng-container *ngIf="creatingNewGame">
                    <h3 align="center"><u>Create New Game</u></h3>

                    <div style="display: block; text-align: center;">
                      <form style="align-content: center">

                        <font size=5>Provided Normal Words</font>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="radio" id="normalWords" name="wordChoice" value="normal"
                          style="position: absolute; right: 38%" [(ngModel)]="wordChoice"><br>
                        <font size=5>Provided Harry Potter Words</font>&nbsp;
                        <input type="radio" id="potterWords" name="wordChoice" value="potter"
                          style="position: absolute; right: 38%" [(ngModel)]="wordChoice"><br>
                        <font size=5>Provided myHogwarts Words</font>&nbsp;
                        <input type="radio" id="mHWords" name="wordChoice" value="myHogwarts"
                          style="position: absolute; right: 38%" [(ngModel)]="wordChoice"><br>
                        <font size=5>Custom List</font>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="radio" id="customWords" name="wordChoice" value="custom"
                          style="position: absolute; right: 38%" [(ngModel)]="wordChoice">
                        <input type="text" name="words" [(ngModel)]="customList"
                          placeholder="This list requires at least 25 words seperated by commas">
                        <hr>
                        <br>
                        <font align="center" size=5>Team with first turn</font><br><br>
                        <font [color]="team1Color" size=3>{{blueName}}:&nbsp;</font><input type="radio" id="blueTurn"
                          name="firstMove" value="blue" [(ngModel)]="firstMove">&nbsp;&nbsp;
                        <font [color]="team2Color" size=3>{{redName}}:&nbsp;</font><input type="radio" id="redTurn"
                          name="firstMove" value="red" [(ngModel)]="firstMove">&nbsp;&nbsp;
                        <font size=3>Random:&nbsp;</font><input type="radio" id="randomTurn" name="firstMove"
                          value="random" [(ngModel)]="firstMove">
                        <br><br>
                      
                        <font size=3>Team with first turn # squares: </font>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <textarea name="openingSquares" [(ngModel)]="openingSquares" cols="2"
                          rows="1">{{openingSquares}}</textarea>
                        <br>
                        <font size=3>Team with second turn # squares: </font>&nbsp;
                        <textarea name="secondarySquares" [(ngModel)]="secondarySquares" cols="2"
                          rows="1">{{secondarySquares}}</textarea>
                        <br>
                        <font size=3># Neutral Squares: </font>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <textarea name="neutralSquares" [(ngModel)]="neutralSquares" cols="2"
                          rows="1">{{neutralSquares}}</textarea>
                        <br>
                        <font size=3># Assassin Squares: </font>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <textarea name="assassinSquares" [(ngModel)]="assassinSquares" cols="2"
                          rows="1">{{assassinSquares}}</textarea>
                       <br><br>
                        <font align="center" size=5>Switch Roles?</font><br><br>
                        <font size=3>Yes:&nbsp;</font><input type="radio" id="blueTurn" name="switchRoles" value="true"
                          [(ngModel)]="switchRoles">&nbsp;&nbsp;
                        <font size=3>No:&nbsp;</font><input type="radio" id="redTurn" name="switchRoles" value="false"
                          [(ngModel)]="switchRoles">&nbsp;&nbsp;
                        <br>
                        <hr>
                        <br>
                        <ng-container *ngIf="!useTimer">
                          <font size=5>Use Timer: </font>&nbsp;<input type="checkbox"
                            (change)="useTimer = !useTimer" /><br><br>
                        </ng-container>
                        <ng-container *ngIf="useTimer">
                          <font size=5>Use Timer: </font>&nbsp;<input type="checkbox" (change)="useTimer = !useTimer"
                            checked /><br><br>
                          <ng-container *ngIf="previousTimer">
                            <font size=3>Previous time settings:</font> &nbsp;<input type="radio" id="oldTimer"
                              name="timerChoice" value="previous" [(ngModel)]="timerChoice"><br>
                            <font size=3>New time settings:</font> &nbsp;<input type="radio" id="newTimer"
                              name="timerChoice" value="new" [(ngModel)]="timerChoice"><br><br>
                          </ng-container>
                          <font size=3>Initial Board Planning Time: </font>&nbsp;
                          <textarea name="initialMinutes" [(ngModel)]="initialMinutes" cols="2"
                            rows="1">{{initialMinutes}}</textarea>
                          &nbsp;: &nbsp;
                          <textarea name="initialSeconds" [(ngModel)]="initialSeconds" cols="3"
                            rows="1">{{initialSeconds}}</textarea>
                          <br>
                          <font size=3>Spymaster turn time: </font>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <textarea name="spyMinutes" [(ngModel)]="spyMinutes" cols="2"
                            rows="1">{{spyMinutes}}</textarea>
                          &nbsp;: &nbsp;
                          <textarea name="spySeconds" [(ngModel)]="spySeconds" cols="3"
                            rows="1">{{spySeconds}}</textarea>
                          <br>
                          <font size=3>Player turn time: </font>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <textarea name="playerMinutes" [(ngModel)]="playerMinutes" cols="2"
                            rows="1">{{playerMinutes}}</textarea>
                          &nbsp;: &nbsp;
                          <textarea name="playerSeconds" [(ngModel)]="playerSeconds" cols="3"
                            rows="1">{{playerSeconds}}</textarea>
                          <br>
                        </ng-container>
                        <br><br>
                        <br>
                        <input type="submit" value="Submit" (click)="submitNewGame()">
                        <br>
                        <input type="submit" value="Cancel" (click)="cancelNewGame()">

                      </form>
                    </div>
                    <br><br><br><br>
                  </ng-container>
                  <ng-container *ngIf="!creatingNewGame">

                    <div *ngIf="isPlayer">
                      <app-player-board [boardId]="boardId" [isParticipant]="true" [role]="role"></app-player-board>
                      <br><br><br><br><br><br><br><br>
                    </div>
                    <div *ngIf="isSpymaster">
                      <app-spymaster-board [boardId]="boardId" [isParticipant]="true" [role]="role">
                      </app-spymaster-board>
                      <br><br><br><br><br><br><br><br><br><br>
                    </div>

                    <div *ngIf="isSpectator">
                      <div *ngIf="spectateView == 'player'">
                        <app-player-board [boardId]="boardId" [isParticipant]="false" [role]="role"></app-player-board>
                        <br><br><br>
                        <div style="text-align: center;">
                          <br><br><br><br><br><br>
                          <button (click)="viewSpymaster()">View Spymaster Board</button>
                        </div>
                      </div>
                      <div *ngIf="spectateView == 'spymaster'">
                        <app-spymaster-board [boardId]="boardId" [isParticipant]="false" [role]="role">
                        </app-spymaster-board>
                        <br><br><br>
                        <div style="text-align: center;">
                          <br><br><br><br><br><br>
                          <button (click)="viewPlayer()">View Player Board</button>
                        </div>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="(board.redScore == 0 || board.blueScore == 0 || board.assassinHit) && role != 'spectator' && !board.isArchive">
                      <input type="submit" (click)="newGame()" value="New Game">
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!board.startMatch  && ((board.includeAdmin && isAdmin) || ( !board.includeAdmin &&role != 'spectator'))">
                  <br><br>
                  <h4 align="center">Once all players have entered and/or you're all ready:</h4>
                  <input type="submit" (click)=startMatch() value="Start Match!">
                  <br><br>
                </ng-container>
                <ng-container *ngIf="!board.startMatch   && !((board.includeAdmin && isAdmin) || ( !board.includeAdmin &&role != 'spectator'))">
                  <br><br>
                  <h3 align="center">Match has yet to begin. Once everybody's ready, a participant/admin will start it.</h3>
                  <h3 align="center">Just chill here and get popcorn ready for the time</h3>
                  <br><br>
                </ng-container>
              </ng-container>
            </mat-tab>

            <mat-tab label="Game History">
              <ng-container *ngFor="let board of boards| async">

                <br>
                <ng-container *ngFor="let turn of board.history; let i = index">
                  <ng-container *ngIf="turn.color == 'blue'">
                    <font [color]="team1Color" size=6>{{i + 1}}.&nbsp; {{turn.clue}}:&nbsp;&nbsp;</font>
                  </ng-container>
                  <ng-container *ngIf="turn.color == 'red'">
                    <font [color]="team2Color" size=6>{{i + 1}}. &nbsp; {{turn.clue}}:&nbsp;&nbsp;</font>
                  </ng-container>

                  <ng-container *ngFor="let guess of turn.guesses; let j = index">
                    <ng-container *ngIf="j != 0">
                      <font color="white" size=6>,&nbsp;</font>
                    </ng-container>
                    <ng-container *ngIf="guess.value == 'blue'">
                      <font [color]="team1Color" size=6>{{guess.word}}</font>
                    </ng-container>
                    <ng-container *ngIf="guess.value == 'red'">
                      <font [color]="team2Color" size=6>{{guess.word}}</font>
                    </ng-container>
                    <ng-container *ngIf="guess.value == 'neutral'">
                      <font color="white" size=6>{{guess.word}}</font>
                    </ng-container>
                    <ng-container *ngIf="guess.value == 'assassin'">
                      <font color="black" style="text-shadow: white;" size=6>{{guess.word}}</font>
                    </ng-container>
                  </ng-container>
                  <br>
                </ng-container>
                <br><br>
                <ng-container *ngIf="!board.startMatch">
                  <h4 align="center">Technology has yet to advance enough to be able to consider future events "history"
                  </h4>
                  <br><br>
                </ng-container>
              </ng-container>
            </mat-tab>
            <ng-container *ngFor="let board of boards | async">
              <ng-container *ngIf="board.firstMatch == false">

                <mat-tab label="Previous Games">
                  <br>
                  <ng-container *ngIf="board.prevGames.length == 0">
                    <div style="text-align: center;">
                      <span>
                        <font color="white" size=6> This was the first game of the match!</font>
                      </span>
                    </div>

                  </ng-container>
                  <ng-container *ngFor="let winner of board.prevWinners; let i = index">

                    <span>
                      <ng-container *ngIf="winner == 'blue'">
                        <font [color]="team1Color" size=6>{{i+1}}.&nbsp; Link: {{board.prevGames[i]}} &nbsp;</font>
                      </ng-container>
                      <ng-container *ngIf="winner == 'red'">
                        <font [color]="team2Color" size=6>{{i+1}}.&nbsp; Link: {{board.prevGames[i]}}&nbsp;</font>
                      </ng-container>
                      <font color="white" size=6>Final Score: &nbsp;</font>
                      <font [color]="team1Color" size=6>{{board.oldBlueScores[i]}}</font>
                      <font color="white" size=6>&nbsp;-&nbsp;</font>
                      <font [color]="team2Color" size=6>{{board.oldRedScores[i]}}</font>
                    </span>
                    <hr>
                  </ng-container>
                  <hr>
                </mat-tab>
              </ng-container>
            </ng-container>
          </mat-tab-group>
        </div>
      </div>
      <br>
      <ng-container *ngFor="let board of boards| async">
        <div style="text-align:center;">Share either the id: {{board.boardId}} or the following link to invite
          friends: https://mycodenames.com/?id={{board.boardId}}</div>
      </ng-container>
      <hr>
      <p style="text-align:center; display: block;">Spectators:</p>
      <ul *ngFor="let spectator of spectators| async">
        <ng-container *ngIf="spectator.Name == name">
          <ng-container *ngIf="spectator.isAdmin">
            <li style="text-align:center"><u>{{spectator.Name}}</u>&nbsp;* </li>
          </ng-container>
          <ng-container *ngIf="!spectator.isAdmin">
            <li style="text-align:center">{{spectator.Name}}&nbsp;* </li>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="spectator.Name != name">
          <ng-container *ngIf="spectator.isAdmin">
            <li style="text-align:center"><u> {{spectator.Name}}</u> </li>
          </ng-container>
          <ng-container *ngIf="!spectator.isAdmin">
            <li style="text-align:center">{{spectator.Name}}
              <ng-container *ngIf="isAdmin && loggedIn">
                <font style="cursor: pointer" color="white" (click)=removeUser(spectator.Name)>&nbsp;X</font>
              </ng-container>
            </li>

          </ng-container>
        </ng-container>
      </ul>
    </div>
</div>
<div *ngIf="!validBoard">
  <h3>The board for this code/URL does not exist. Go to homepage to create one</h3>
</div>